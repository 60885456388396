import { Box, Checkbox, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  ApplicationProps,
  GroupSignatories,
  POAAuthorisedSignatories,
} from '../../redux-store/types/api-types';
import { WebViewDataRow } from '../DataTable';
import { poaAuthorisedheaders } from '../NonIndividualInvestor/Authorised';
import { checkIfApplicationIsNonIndividualPOA } from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { POA_Authorized_signatory_can_Esign_Enable } from '../../utils/constant';

const headers = [
  {
    header: 'Name',
    label: 'name',
    valueGetter: (row: GroupSignatories) => row.name || 'N/A',
  },
  {
    header: 'PAN',
    valueGetter: (row: GroupSignatories) => row?.pan?.toUpperCase() || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: GroupSignatories) => row.email || 'N/A',
  },
  {
    header: 'Designation',
    valueGetter: (row: GroupSignatories) => row?.designation || 'N/A',
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: GroupSignatories) =>
      row.mobile && row.countryCode ? row.countryCode + '-' + row.mobile : row.mobile || 'N/A',
  },
];
const sendToEsignCell = {
  header: (): JSX.Element => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            fontWeight: 500,
            color: 'primary.main',
            pr: 0.5,
          }}
          component="span">
          Send e-sign link
        </Typography>
        <InfoOutlinedIcon
          color="info"
          fontSize="small"
          onClick={handleOpen}
          sx={{ position: 'relative', top: 4 }}
        />

        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              maxWidth: 400,
              p: { xs: 2, sm: '15px 20px 45px' },
              borderRadius: '10px',
              height: 'unset',
              overflowY: 'auto',
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
            <CloseIcon fontSize="medium" />
          </IconButton>
          <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
            <InfoOutlinedIcon color="info" fontSize="large" />
          </Box>
          <Box
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
              '.MuiTypography-root': {
                fontSize: 20,
                fontWeight: 500,
                textAlign: 'center',
              },
            }}>
            <Typography sx={{ color: 'secondary.main', lineHeight: '35px' }}>
              Use this option to send the e-sign link to specific set of authorized signatories.
            </Typography>
          </Box>
        </Dialog>
      </>
    );
  },
  renderEditDeleteCell: (row: POAAuthorisedSignatories | GroupSignatories): JSX.Element => {
    return (
      <Checkbox
        checked={row.canEsign}
        sx={{ display: { xs: 'block' }, ':hover': { backgroundColor: 'white' } }}
        disabled
      />
    );
  },
};

export const POAAuthorizedSignatry = (): JSX.Element => {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { poaauthorisedsignatories = [], requiredAuthorisedSignatory } = application || {};
  return (
    <>
      <Grid container pt={3}>
        <Typography>
          Total No. of required authorised signatories: {requiredAuthorisedSignatory}
        </Typography>
      </Grid>
      <Grid container pt={3}>
        <Grid xs={12} alignSelf="center">
          <WebViewDataRow
            tableData={poaauthorisedsignatories}
            tableHeader={[
              ...(POA_Authorized_signatory_can_Esign_Enable ? [sendToEsignCell] : []),
              ...poaAuthorisedheaders,
            ]}
            renderAdditionalRow={false}
            tableHeaderCustomStyles={{
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                fontWeight: 500,
                color: 'primary.main',
              },
            }}
            rowCustomStyles={{
              '.MuiTableCell-root': {
                py: '8px',
                overflowWrap: 'anywhere',
                padding: { xs: '10px', sm: '30px' },
              },
            }}
            tableBodyCustomStyles={{
              '.MuiTableRow-root': {
                '&:last-child': {
                  borderBottom: 'none',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default function AuthorizedSignatories({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { groups = [], poaauthorisedsignatories = [] } = application;
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Authorised Signatories
            </Typography>
            {((!checkIfApplicationIsNonIndividualPOA(application) && groups.length === 0) ||
              (checkIfApplicationIsNonIndividualPOA(application) &&
                poaauthorisedsignatories.length === 0)) && (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Authorised Signatories
              </Grid>
            )}
            {checkIfApplicationIsNonIndividualPOA(application) &&
            poaauthorisedsignatories.length > 0 ? (
              <POAAuthorizedSignatry />
            ) : (
              <>
                {groups.map((group, index) => {
                  return (
                    <React.Fragment key={group.id}>
                      <Grid container pt={3}>
                        <Typography>
                          Total No. of required authorised signatories from this group:{' '}
                          {group.threshold}
                        </Typography>
                      </Grid>
                      <Grid container pt={3}>
                        <Grid xs={12} alignSelf="center">
                          <WebViewDataRow
                            groupIndex={index}
                            tableData={group.groupsignatories.filter(
                              (_signatory) => _signatory.isActive
                            )}
                            tableHeader={[sendToEsignCell, ...headers]}
                            renderAdditionalRow={false}
                            tableHeaderCustomStyles={{
                              '.MuiTableCell-head': {
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: 14,
                                fontWeight: 500,
                                color: 'primary.main',
                              },
                            }}
                            rowCustomStyles={{
                              '.MuiTableCell-root': {
                                py: '8px',
                                overflowWrap: 'anywhere',
                                padding: { xs: '10px', sm: '30px' },
                              },
                            }}
                            tableBodyCustomStyles={{
                              '.MuiTableRow-root': {
                                '&:last-child': {
                                  borderBottom: 'none',
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

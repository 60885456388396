import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Bank } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { SubHeading } from '../investors/components';
import { getBankAddress, partitionArray } from '../../utils/utilityFunctions';
import { styles } from '../../Styles/styles';

function CommenBankDetails({
  banks,
  bankTitle = "Investor's Bank Details",
}: {
  banks: Partial<Bank>[];
  bankTitle?: string;
}): JSX.Element {
  return (
    <>
      <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
        {bankTitle}
      </Typography>
      {banks?.length === 0 ? (
        <Grid
          xs={12}
          sm={12}
          sx={{
            fontSize: 18,
            fontWeight: 600,
            color: 'common.black',
            textAlign: 'center',
            my: 3,
          }}>
          No Bank Details
        </Grid>
      ) : (
        banks?.map((bank, index: number) => {
          const title = ['Bank 1 Details', 'Bank 2 Details', 'Bank 3 Details'][index];
          const bankAddress = getBankAddress(bank.address1, bank.address2, bank.address3);
          return (
            <React.Fragment key={bank.id}>
              <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
              <Gridstyles>
                <Grid
                  item
                  container
                  pt={2}
                  pb={5}
                  rowSpacing={6}
                  columnSpacing={13}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <KeyValue title={'IFSC Code'} description={bank.ifscCode} />
                  <KeyValue title={'Bank Account Number'} description={bank.bankAccountNumber} />
                  <KeyValue title={'MICR Code'} description={bank.micrCode} />
                  <KeyValue title={'Bank Type'} description={bank.bankAccountType} />
                  <KeyValue title={'Bank Name'} description={bank.bankName} />
                  <KeyValue title={'Branch Name'} description={bank.bankBranch} />
                  <KeyValue
                    title={`Bank Address`}
                    description={`${bankAddress}`}
                    sx={{
                      textTransform: 'capitalize',
                    }}
                  />
                  <KeyValue
                    title={`Default Bank Account`}
                    description={bank.defaultBankAccount ? 'Yes' : '-'}
                  />
                  <KeyValue
                    title={`Penny Drop Verified`}
                    description={
                      bank.pennydropCheck ? (bank.pennydropVerified ? 'Yes' : 'No') : '-'
                    }
                  />
                </Grid>
              </Gridstyles>
            </React.Fragment>
          );
        })
      )}
    </>
  );
}

export default function BankDetails({
  banks = [],
  displayTopUpBanks = false,
}: {
  banks: Partial<Bank>[];
  displayTopUpBanks: boolean | null;
}): JSX.Element {
  const [topUpBanks, normalBanks] = partitionArray(banks);
  return (
    <>
      <Box sx={styles.ApplicationDetailsCommonLayout} mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <CommenBankDetails banks={normalBanks} />
            {displayTopUpBanks && (
              <CommenBankDetails banks={topUpBanks} bankTitle={`Investor's Top Up Bank Details`} />
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

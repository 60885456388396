/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MFCheckbox from '../../lib/formik/Checkbox';
import { NomineeType } from '../../redux-store/types/api-types';
import {
  applicationComparison,
  getApplicantName,
  getNomineeRelation,
  getStep,
  isMinor,
  isOnboardingTypeMinor,
  removeSingleQuote,
  saveForLater,
  statusRoleBased,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { nomineeDetailsSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import { NomineeRelations, SAVE_LATER_VALIDATION_CHECK } from '../../utils/constant';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import FieldValidationNote from './FieldValidationNote';

type updateNominee = NomineeType & {
  Relationship: string | null;
  sno?: number;
};

type Values = {
  doNotWishToNominate: boolean;
  nominees: updateNominee[];
  saveType: string;
};

const newNominee: updateNominee = {
  nomineeName: '',
  nomineeRelationship: '',
  dateOfBirth: null,
  nomineePercentage: 0,
  guardianName: '',
  nomineePan: '',
  nomineeGuardianPan: '',
  isActive: true,
  Relationship: '',
  sno: 0,
};

const initialValues: Values = {
  doNotWishToNominate: true,
  nominees: [newNominee],
  saveType: 'save and proceed',
};

const Nominee = ({
  nomineeName,
  index,
  values,
  ifTopUp,
}: {
  nomineeName: number;
  index: number;
  values: Values;
  ifTopUp: boolean;
}): JSX.Element => {
  const { dateOfBirth = '' } = values.nominees[index];
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineeName`}
          label={`Nominee Name ${nomineeName} *`}
          placeholder={`Enter Nominee Name ${nomineeName}`}
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`nominees.${index}.Relationship`}
          label="Nominee Relationship *"
          items={NomineeRelations.filter((relation) => {
            const { nominees } = values;
            const selectedRelations = nominees
              .filter(
                (nominee, ind) =>
                  ind !== index &&
                  nominee.Relationship &&
                  nominee.isActive &&
                  ['MOTHER', 'FATHER', 'SPOUSE'].includes(nominee.Relationship)
              )
              .map((nominee) => nominee.Relationship);
            return !selectedRelations.includes(relation);
          }).map((relation) => ({
            key: relation,
            value: relation,
          }))}
          disabled={ifTopUp}
        />
        {values.nominees.map((nominee, idx: number) => {
          if (nominee.Relationship === 'OTHERS' && idx === index) {
            return (
              <MFTextField
                name={`nominees.${index}.nomineeRelationship`}
                label="Please Specify Relationship *"
                placeholder={`Enter Nominee Relationship`}
                disabled={ifTopUp}
              />
            );
          }
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Date of birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of birth'}
          name={`nominees.${index}.dateOfBirth`}
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePercentage`}
          label="Nominee % *"
          placeholder="Enter Nominee %"
          disabled={ifTopUp}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePan`}
          label={`Nominee PAN`}
          placeholder="Enter Nominee PAN"
          inputProps={{ style: { textTransform: 'uppercase' } }}
          onChange={(e) => {
            setFieldValue(`nominees.${index}.nomineePan`, e.target.value.toUpperCase());
          }}
          disabled={ifTopUp}
        />
      </Grid>
      {isMinor(dateOfBirth ? dateOfBirth : '') && (
        <>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.guardianName`}
              label="Guardian Name *"
              placeholder="Enter Guardian Name"
              disabled={ifTopUp}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.nomineeGuardianPan`}
              label="Guardian PAN"
              placeholder="Enter Guardian Pan"
              inputProps={{ style: { textTransform: 'uppercase' } }}
              onChange={(e) => {
                setFieldValue(`nominees.${index}.nomineeGuardianPan`, e.target.value.toUpperCase());
              }}
              disabled={ifTopUp}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default function NomineeDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [nomineesData, setNomineesData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [nomineeToDelete, setNomineeToDelete] = useState<{
    nominee: Partial<NomineeType>;
    index: number;
  } | null>(null);
  // const [nomineesToDelete, setNomineesToDelete] = useState<
  //   | {
  //       nominee: Partial<NomineeType>;
  //       // index: number;
  //     }[]
  //   | null
  // >(null);
  const [bulkNomineeDelete, setBulkNomineeDelete] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: Values) => {
    try {
      const {
        nominees: existingNominees = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
        topupApplicationNumber,
      } = application || {};
      const { nominees, doNotWishToNominate, saveType } = values;
      const updatedPayload = {
        ...application,
        doNotWishToNominate,
        nominees: nominees
          .filter((nominee) => nominee?.id || nominee.isActive)
          .map((nominee, index) => {
            const { id = null } = existingNominees[index] || {};
            if (nominee.id === id) {
              const { Relationship, sno, ...rest } = nominee;
              return {
                ...(existingNominees[index] || {}),
                ...rest,
                nomineeRelationship: getNomineeRelation(Relationship)
                  ? Relationship
                  : removeSingleQuote(nominee.nomineeRelationship),
                dateOfBirth: !nominee.dateOfBirth
                  ? null
                  : new Date(nominee.dateOfBirth).toISOString(),
                isActive: nominee.isActive && !doNotWishToNominate,
                nomineeName: removeSingleQuote(rest.nomineeName),
                guardianName: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianName)
                  : null,
                nomineeGuardianPan: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.nomineeGuardianPan)
                  : null,
                nomineePercentage:
                  nominee.nomineePercentage === 0 || nominee.nomineePercentage
                    ? Number(nominee.nomineePercentage)
                    : null,
              };
            }
            const { Relationship, sno, ...rest1 } = nominees[nominee.sno as number];
            return {
              ...rest1,
              nomineeRelationship: removeSingleQuote(rest1.nomineeRelationship),
              nomineeName: removeSingleQuote(rest1.nomineeName),
              guardianName: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianName)
                : null,
              nomineeGuardianPan: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.nomineeGuardianPan)
                : null,
            };
          }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          nominees: application?.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
        },
        {
          ...updatedPayload,
          nominees: updatedPayload.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
          currentStep: !!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status: statusRoleBased(role, application),
              currentStep: getStep(6, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
              //!!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('bank-details', {
              id,
              applicant1ReferenceId,
              topUpApplication: application?.topupInitiated,
            })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        !isSaveLater
          ? history.push('bank-details', {
              id,
              applicant1ReferenceId,
              topUpApplication: application?.topupInitiated,
            })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    const { nominees = [], doNotWishToNominate = true, onboardingType = '' } = application || {};
    setNomineesData({
      ...nomineesData,
      doNotWishToNominate: isOnboardingTypeMinor(onboardingType)
        ? true
        : nominees.length
        ? false
        : doNotWishToNominate === null
        ? true
        : doNotWishToNominate,
      nominees: nominees.length
        ? nominees.map((nominee, index) => ({
            ...nominee,
            Relationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? nominee.nomineeRelationship
                ? nominee.nomineeRelationship.toUpperCase()
                : nominee.nomineeRelationship
              : 'OTHERS',
            nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
              ? ''
              : nominee.nomineeRelationship,
            sno: index,
            nomineePercentage: nominee.nomineePercentage
              ? nominee.nomineePercentage
              : nominees.length === 1
              ? 100
              : 0,
            isActive: isOnboardingTypeMinor(onboardingType) ? !nominee.isActive : nominee.isActive,
          }))
        : [],
    });
  }, [application]);

  return (
    <Formik
      initialValues={nomineesData}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nomineeDetailsSchema(application?.applicants), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, errors }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes displayContent={'Investor(s) have the option to choose upto three nominees'} />
          <Grid item xs={12}>
            <MFCheckbox
              // disabled={!!values.nominees.length}
              disabled={
                isOnboardingTypeMinor(application?.onboardingType || '') ||
                application?.topupInitiated ||
                false
              }
              name="doNotWishToNominate"
              label="I do not wish to nominate."
              sx={{ letterSpacing: '1px' }}
              onChange={({ target: { checked } }) => {
                const updatedNominees = !checked
                  ? [
                      ...values.nominees,
                      { ...newNominee, nomineePercentage: 100, sno: values.nominees.length },
                    ]
                  : values.nominees;
                setValues({
                  ...nomineesData,
                  doNotWishToNominate:
                    values.nominees?.filter((item) => item.isActive).length === 0
                      ? checked
                      : !checked,
                  nominees: updatedNominees.map((nominee) => ({
                    ...nominee,
                  })),
                });
                checked && setBulkNomineeDelete(true);
              }}
              checked={values.doNotWishToNominate}
            />
          </Grid>

          {values.nominees
            .filter((_nominee) => _nominee.isActive)
            .map((nominee, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}>
                  {getApplicantName(index + 1, true)} Nominee Details
                  {!application?.topupInitiated && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => setNomineeToDelete({ nominee, index: nominee.sno as number })}>
                      <Typography
                        sx={{
                          color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          cursor: 'pointer',
                        }}>
                        Remove Nominee{' '}
                      </Typography>
                      <IconButton sx={{ p: '2px' }}>
                        <CardMedia
                          component="img"
                          src="/images/delete-red.svg"
                          alt="Delete Icon"
                          sx={{ width: 'unset' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>
                <Nominee
                  nomineeName={index + 1}
                  index={nominee.sno as number}
                  key={nominee.sno as number}
                  values={values}
                  ifTopUp={application?.topupInitiated || false}
                />
              </React.Fragment>
            ))}

          {values.nominees.filter((item) => item.isActive).length < 3 && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={
                  isOnboardingTypeMinor(application?.onboardingType || '') ||
                  application?.topupInitiated ||
                  false
                }
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 2,
                }}
                onClick={() =>
                  setValues({
                    ...values,
                    nominees: [
                      ...values.nominees,
                      {
                        ...newNominee,
                        nomineePercentage: values.nominees.filter((item) => item.isActive).length
                          ? 0
                          : 100,
                        sno: values.nominees.length,
                      },
                    ],
                    doNotWishToNominate: false,
                  })
                }>
                Add Nominee
              </Button>
            </Grid>
          )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee.Relationship,
                })),
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    nominee?.Relationship === 'OTHERS'
                      ? nominee.nomineeRelationship
                      : nominee?.Relationship,
                })),
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete nominees ?'}
            open={nomineeToDelete !== null || bulkNomineeDelete !== false}
            setOpen={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedApplicants = values.nominees.map((nominee, i) => {
                  if (nominee.sno === nomineeToDelete?.index || bulkNomineeDelete) {
                    return {
                      ...nominee,
                      isActive: false,
                    };
                  }
                  return nominee;
                });
                setValues({
                  ...values,
                  nominees: remainedApplicants,
                  doNotWishToNominate:
                    remainedApplicants.filter((item) => item.isActive).length === 0
                      ? true
                      : values.doNotWishToNominate,
                });
                setBulkNomineeDelete(false);
                setNomineeToDelete(null);
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}

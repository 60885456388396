import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { LoadingButton } from '@mui/lab';
import { logout } from '../redux-store/actions/auth';
import { useDispatch } from 'react-redux';
export const PageNotFound = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    try {
      setLoading(true);
      history.replace('/');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          textAlign: 'center',
          color: '#4f4f4f',
        },
        '& .MuiDialogContent-root': {
          textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '& .MuiButton-root': {
            width: '160px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: '18px',
            py: 1,
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      <DialogContent>
        <DialogContentText>Page you are requesting is not found.....</DialogContentText>
        <DialogContentText>
          By clicking OK, you will be redirected to the Home page
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loadingPosition="start"
          type="submit"
          loading={loading}
          variant="contained"
          fullWidth={true}
          onClick={onClick}>
          OK
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const PageNotFound2 = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  //   const handleBackHome = () => {
  //     history.push('/');
  //   };
  const onClick = async () => {
    try {
      setLoading(true);
      await dispatch(logout());
      history.replace('/login');
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}>
      <Typography variant="h2" component="h1" style={{ marginBottom: '16px' }}>
        Oops! Page Not Found
      </Typography>

      <Typography variant="h4" style={{ marginBottom: '24px' }}>
        Sorry, we can&apos;t find the page you&apos;re looking for....
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          '.MuiButton-root': {
            minWidth: { xs: '100px', sm: '150px', md: '200px' },
            maxWidth: '400px',
            mt: 4,
            fontWeight: 600,
            lineHeight: 1.1,
            mx: 1.5,
          },
        }}>
        {/* <LoadingButton
          loadingPosition="start"
          variant="contained"
          sx={{ color: 'common.white', }}
          onClick={handleBackHome}>
          Go Back Home
        </LoadingButton> */}
        <LoadingButton
          loadingPosition="start"
          variant="contained"
          sx={{ color: 'common.white' }}
          onClick={() => history.goBack()}
          disabled={loading}>
          Go Back
        </LoadingButton>
        <LoadingButton
          loadingPosition="start"
          loading={loading}
          variant="outlined"
          sx={{ color: 'primary.main' }}
          onClick={onClick}>
          Login Again
        </LoadingButton>
      </Grid>
    </Container>
  );
};

import { Grid, Typography } from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Applicant, Broker, BrokerList } from '../../redux-store/types/api-types';
import {
  DLCLMasters,
  grossAnnualMasters,
  investorTypeMasters,
  PEPsMasters,
  SAVE_LATER_VALIDATION_CHECK,
} from '../../utils/constant';
import { DatePicker } from '../../lib/formik/DatePicker';
import React, { useEffect, useState } from 'react';
import {
  applicationComparison,
  clearclIdField,
  cleardpIdField,
  currencyConversion,
  getStep,
  getclIdField,
  getdpIdField,
  isCDSL,
  nameOfBrokerExists,
  otherNameOfBroker,
  removeSingleQuote,
  saveForLater,
  sortListKeepOthersLast,
  statusRoleBased,
} from '../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { NonIndividualContributorValidationSchema } from '../../utils/schema';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { useFeatureToggles, useMdmsBrokerListData } from '../../utils/useDataMdms';
import FieldValidationNote from '../investors/FieldValidationNote';

const contributorObject: Partial<Applicant & { otherNameOfBroker: string }> = {
  name: '',
  cityOfIncorporation: '',
  ckycNo: '',
  investorType: '',
  dateOfBirth: '',
  panNo: '',
  grossAnnualIncome: '',
  kraVerified: '',
  amlCheck: false,
  netWorth: '',
  politicallyExposedPersonStatus: '',
  dlclId: '',
  nameOfBroker: '',
  kraMobileNumber: '',
  kraEmailId: '',
  otherNameOfBroker: '',
};

export type Values = {
  saveType: string;
  modeOfHolding: string;
  applicants: Partial<Applicant & { otherNameOfBroker: string }>[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  saveType: 'save and proceed',
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [brokerList, setBrokerList] = useState<BrokerList>();
  const { BrokerList } = useMdmsBrokerListData();
  const { toggler } = useFeatureToggles();

  useEffect(() => {
    (async function () {
      try {
        setBrokerList(BrokerList as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [BrokerList]);

  useEffect(() => {
    (async function () {
      try {
        const { applicants = [] } = application || {};
        setContributorValues({
          ...contributorValues,
          applicants: applicants.length
            ? applicants.map((applicant) => {
                return {
                  ...applicant,
                  // eslint-disable-next-line
                  amlCheck: (applicant.amlCheck ? 'Yes' : 'No') as any,
                  dlclId: applicant.dlclId ? applicant.dlclId : '',
                  nameOfBroker: nameOfBrokerExists(
                    brokerList?.broker_list as Broker[],
                    applicant.nameOfBroker || ''
                  )
                    ? applicant.nameOfBroker
                    : applicant.nameOfBroker
                    ? 'others'
                    : '',
                  otherNameOfBroker: nameOfBrokerExists(
                    brokerList?.broker_list as Broker[],
                    applicant.nameOfBroker || ''
                  )
                    ? ''
                    : applicant.nameOfBroker,
                  investorType: applicant.investorType || '',
                };
              })
            : [contributorObject],
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, BrokerList]);

  const handleApplicant = async (values: Values) => {
    const { saveType, applicants = [] } = values;
    const {
      id,
      currentStep,
      banks = [],
      applicant1ReferenceId = '',
      applicationNumber,
      topupApplicationNumber,
    } = application || {};
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks:
          applicants[0].name?.split("'").join('').trim() ===
          application?.applicants[0].name?.split("'").join('').trim()
            ? banks
            : banks.map((bank) => ({
                ...bank,
                pennydropCheck: false,
                pennydropVerified: false,
              })),
        applicants: applicants
          .map((applicant) => {
            const { otherNameOfBroker: othersNameOfBroker, ...rest } = applicant;
            return {
              ...application?.applicants[0],
              ...rest,
              name: removeSingleQuote(applicant.name),
              cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
              // eslint-disable-next-line
              amlCheck: (applicant.amlCheck as any) === 'Yes' ? true : false,
              dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant?.dpId?.toUpperCase(),
              nameOfBroker: cleardpIdField(applicant.dlclId || '')
                ? ''
                : otherNameOfBroker(applicant.nameOfBroker || '')
                ? othersNameOfBroker?.toString()
                : applicant.nameOfBroker,
            };
          })
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        modeOfHolding: 'single',
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );

    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks:
              applicants[0].name?.split("'").join('').trim() ===
              application?.applicants[0].name?.split("'").join('').trim()
                ? banks
                : banks.map((bank) => ({
                    ...bank,
                    pennydropCheck: false,
                    pennydropVerified: false,
                  })),
            applicants: applicants.map((applicant) => {
              const { otherNameOfBroker: othersNameOfBroker, ...rest } = applicant;
              return {
                ...application?.applicants[0],
                ...rest,
                name: removeSingleQuote(applicant.name),
                cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
                // eslint-disable-next-line
                amlCheck: (applicant.amlCheck as any) === 'Yes' ? true : false,
                clId: clearclIdField(applicant.dlclId || '') ? '' : applicant.clId,
                dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant?.dpId?.toUpperCase(),
                nameOfBroker: cleardpIdField(applicant.dlclId || '')
                  ? ''
                  : otherNameOfBroker(applicant.nameOfBroker || '')
                  ? othersNameOfBroker?.toString()
                  : applicant.nameOfBroker,
              };
            }),
            modeOfHolding: 'single',
            status: statusRoleBased(role, application),
            currentStep: getStep(2, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(
          `Application ${
            application?.topupInitiated ? topupApplicationNumber : applicationNumber
          } - ` + ' Saved successfully',
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      }
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            NonIndividualContributorValidationSchema(
              brokerList?.broker_list as Broker[],
              application?.createdAt || '',
              toggler
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.name`}
                    label="Name of Entity *"
                    placeholder="Enter Name of Entity"
                    disabled={
                      applicant.dataFetchedFromKRA || false || !!application?.topupInitiated
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Date of Registration/Incorporation *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name={`applicants.${index}.dateOfBirth`}
                    disabled={
                      applicant.dataFetchedFromKRA || false || !!application?.topupInitiated
                    }
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.cityOfIncorporation`}
                    label="Place of Registration/Incorporation *"
                    placeholder="Enter Place of Registration/Incorporation"
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.ckycNo`}
                    label="CKYC No./CKYC Acknowledge No./KIN No "
                    placeholder="Enter CKYC No./CKYC Acknowledge No./KIN No"
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.kraVerified`}
                    label="CVL KRA Verified"
                    placeholder="Enter CVL KRA Verified"
                    sx={{
                      '& .MuiInputBase-input': {
                        textTransform: 'capitalize',
                      },
                    }}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.amlCheck`}
                    label="AML Verified"
                    placeholder="Enter AML Verified"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.investorType`}
                    label="Investor Type *"
                    items={Object.keys(investorTypeMasters).map((investor) => ({
                      key: investorTypeMasters[investor],
                      value: investor,
                    }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.panNo`}
                    label="PAN *"
                    placeholder="Enter PAN"
                    disabled={true}
                  />
                </Grid>
                {applicant.kraMobileNumber && (
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`applicants.${index}.kraMobileNumber`}
                      label={`KRA Mobile Number`}
                      placeholder="Enter KRA Mobile Number"
                      disabled={true}
                    />
                  </Grid>
                )}
                {applicant.kraEmailId && (
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`applicants.${index}.kraEmailId`}
                      label={`KRA Email Id`}
                      placeholder="Enter KRA Email Id"
                      disabled={true}
                    />
                  </Grid>
                )}
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Other Details
                </SubHeading>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.grossAnnualIncome`}
                    label="Gross Annual Income *"
                    items={grossAnnualMasters.map((grossIncome) => ({
                      key: grossIncome,
                      value: grossIncome,
                    }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.netWorth`}
                    label="Net Worth *"
                    placeholder="Enter Net Worth"
                    disabled={!!application?.topupInitiated}
                  />
                  {values.applicants[index].netWorth &&
                    !isNaN(Number(values.applicants[index].netWorth)) && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.applicants[index].netWorth)}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.politicallyExposedPersonStatus`}
                    label="Politically Exposed Person (PEP) Status"
                    items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.dlclId`}
                    label="Demat Account Details (for allotment of units) *"
                    items={Object.keys(DLCLMasters).map((Id) => ({
                      key: DLCLMasters[Id],
                      value: Id,
                    }))}
                    disabled={!!application?.topupInitiated}
                  />
                </Grid>
                {getdpIdField(values.applicants[index].dlclId || '') && (
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`applicants.${index}.dpId`}
                      label={`${
                        isCDSL(values.applicants[index].dlclId || '') ? 'DPCL ID' : 'DP ID'
                      } *`}
                      placeholder={`Enter ${
                        isCDSL(values.applicants[index].dlclId || '') ? 'DPCL ID' : 'DP ID'
                      }`}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      disabled={!!application?.topupInitiated}
                    />
                  </Grid>
                )}
                {getclIdField(values.applicants[index].dlclId || '') && (
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`applicants.${index}.clId`}
                      label="CL ID *"
                      placeholder="Enter CL ID"
                      disabled={!!application?.topupInitiated}
                    />
                  </Grid>
                )}
                {getdpIdField(values.applicants[index].dlclId || '') && (
                  <Grid item xs={12} sm={6}>
                    <SearchableSelect
                      name={`applicants.${index}.nameOfBroker`}
                      label="Name of Broker/Depository Participant (DP)"
                      items={sortListKeepOthersLast(brokerList?.broker_list as Broker[])?.map(
                        (broker: Broker) => ({
                          key: broker.value,
                          value: broker.key,
                        })
                      )}
                      searchFieldPlaceholder={'Search Broker/Depository Participant'}
                      disabled={!!application?.topupInitiated}
                    />
                  </Grid>
                )}
                {getdpIdField(values.applicants[index].dlclId || '') &&
                  otherNameOfBroker(values.applicants[index].nameOfBroker || '') && (
                    <Grid item xs={12} sm={6}>
                      <MFTextField
                        name={`applicants.${index}.otherNameOfBroker`}
                        label="Name of Broker *"
                        placeholder="Enter Name of Broker"
                        disabled={!!application?.topupInitiated}
                      />
                    </Grid>
                  )}
              </React.Fragment>
            ))}
            <FieldValidationNote />
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        </>
      )}
    </Formik>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading, SubHeading2 } from './components';
import {
  Formik,
  FormikHelpers,
  useField,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import AddIcon from '@mui/icons-material/Add';
import { ApplicationProps, Bank } from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateApplication, pennyDropVerification } from '../../redux-store/actions/application';
import MFCheckbox from '../../lib/formik/Checkbox';
import {
  applicationComparison,
  getApplicantName,
  getBankAddress,
  getStep,
  partitionArray,
  saveForLater,
  statusRoleBased,
} from '../../utils/utilityFunctions';
import { Box } from '@mui/system';
import { ConfirmationDialog } from '../commonComponents';
import { getIFSCDetails } from '../../redux-store/actions';
import { IfscType } from '../../redux-store/types/mdms';
import { bankDetailsSchema } from '../../utils/schema';
import {
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  NRIBankAccountTypeMaster,
  USER_ROLES,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  handleTopUp,
  AllowMultipleBank,
  AddTopUpBankAccount,
} from '../../utils/constant';
import { Notes } from './components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { showError } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { accountNumberRegex, ifscRegex } from '../../utils/regex';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FieldValidationNote from './FieldValidationNote';

type updatedBank = Bank & {
  loading: boolean;
  bankAddress: string;
};

export const bankObject: updatedBank = {
  ifscCode: '',
  bankAccountNumber: '',
  bankAccountType: '',
  bankName: '',
  bankBranch: '',
  defaultBankAccount: false,
  isActive: true,
  pennydropCheck: false,
  pennydropVerified: false,
  address1: '',
  address2: '',
  address3: '',
  micrCode: '',
  bankAddress: '',
  loading: false,
  isTopup: false,
};

type Values = {
  status?: string;
  applicationType?: string;
  banks: updatedBank[];
  topupBanks: updatedBank[];
  saveType: string;
};

const initialValues: Values = {
  status: '',
  applicationType: '',
  banks: [bankObject],
  topupBanks: [bankObject],
  saveType: 'save and proceed',
};

function BankTextField({
  name,
  bankFieldName,
  branchFieldName,
  disabled = false,
  pennydropFieldCheck,
  address1FieldName,
  address2FieldName,
  address3FieldName,
  micrFieldName,
  addressFieldName,
}: {
  name: string;
  bankFieldName: string;
  branchFieldName: string;
  disabled?: boolean;
  pennydropFieldCheck: string;
  address1FieldName: string;
  address2FieldName: string;
  address3FieldName: string;
  micrFieldName: string;
  addressFieldName: string;
}): JSX.Element {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_field, _meta, helpers] = useField(name);
  // eslint-disable-next-line
  const [code, setCode] = useState<string>(_field.value);
  const { setFieldValue } = useFormikContext();
  const { setValue, setError } = helpers;
  const inputRef = useRef<HTMLInputElement>();

  const getIFSCResponse = (value: string) => {
    let isComponentAlive = true;
    if (!value || value.length < 12) {
      setValue((value || '').toUpperCase());
      setFieldValue(bankFieldName, '');
      setFieldValue(branchFieldName, '');
      setFieldValue(address1FieldName, '');
      setFieldValue(address2FieldName, '');
      setFieldValue(address3FieldName, '');
      setFieldValue(micrFieldName, '');
      setFieldValue(addressFieldName, '');
    }
    if (value?.length !== 11) {
      return;
    }
    inputRef.current && inputRef.current.blur();
    (async function () {
      try {
        const { BankName, BankCity, BankAdd1, BankAdd2, BankAdd3, MicrNo } = (await dispatch(
          getIFSCDetails(value)
        )) as unknown as IfscType;
        if (!isComponentAlive) {
          return;
        }
        setFieldValue(bankFieldName, BankName?.trim());
        setFieldValue(branchFieldName, BankCity?.trim());
        setFieldValue(address1FieldName, BankAdd1?.trim());
        setFieldValue(address2FieldName, BankAdd2?.trim());
        setFieldValue(address3FieldName, BankAdd3?.trim());
        setFieldValue(micrFieldName, MicrNo?.trim());
        setFieldValue(
          addressFieldName,
          (BankAdd2 ? BankAdd1?.trim() + ',' : BankAdd1?.trim()) +
            (BankAdd3 ? BankAdd2?.trim() + ',' : BankAdd2?.trim()) +
            BankAdd3?.trim()
        );
      } catch (error) {
        setError('IFSC Code is invalid');
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  };
  function onChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue(pennydropFieldCheck, false);
    setCode(value);
    getIFSCResponse(value);
  }
  return (
    <MFTextField
      name={name}
      label="IFSC Code *"
      placeholder="Enter IFSC Code"
      onChange={onChange}
      inputRef={inputRef}
      disabled={disabled}
      trimOnBlur={false}
    />
  );
}

/**
 * Component to render a bank account details form field.
 * @param {Object} props - The props object.
 * @param {number} props.index - The index of the bank account.
 * @param {boolean} props.isFieldDisabled - Whether the field should be disabled or not.
 * @param {Object} props.application - The application object.
 * @param {Object} props.values - The values object.
 * @param {Function} props.setFieldValue - The function to set the field value.
 * @param {Object} props.bank - The bank object.
 * @param {Function} props.handleBankVerification - The function to handle bank verification.
 * @param {Function} props.pennyDropCheck - The function to check if penny drop is applicable or not.
 * @param {boolean} [props.topUpSection=false] - Whether to show the top-up section or not.
 */
function BankAccountDetails({
  index,
  isFieldDisabled,
  application,
  values,
  setFieldValue,
  bank,
  handleBankVerification,
  pennyDropCheck,
  topUpSection = false,
}: {
  index: number;
  isFieldDisabled: boolean;
  application: ApplicationProps | null;
  values: Values;
  setFieldValue: FormikHelpers<Values>['setFieldValue'];
  bank: updatedBank;
  handleBankVerification: (values: Values, index: number, isTopup: boolean) => void;
  pennyDropCheck: (val: Values, isTopup: boolean) => boolean;
  topUpSection?: boolean;
}) {
  const requiredBanks = topUpSection ? values.topupBanks : values.banks;
  const labelPrefix = topUpSection ? 'topupBanks' : 'banks';
  return (
    <>
      <Grid item xs={12} sm={6}>
        <BankTextField
          name={`${labelPrefix}.${index}.ifscCode`}
          bankFieldName={`${labelPrefix}.${index}.bankName`}
          branchFieldName={`${labelPrefix}.${index}.bankBranch`}
          disabled={isFieldDisabled}
          pennydropFieldCheck={`${labelPrefix}.${index}.pennydropCheck`}
          address1FieldName={`${labelPrefix}.${index}.address1`}
          address2FieldName={`${labelPrefix}.${index}.address2`}
          address3FieldName={`${labelPrefix}.${index}.address3`}
          micrFieldName={`${labelPrefix}.${index}.micrCode`}
          addressFieldName={`${labelPrefix}.${index}.bankAddress`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${labelPrefix}.${index}.bankAccountNumber`}
          label="Bank Account Number *"
          placeholder="Enter Bank Account Number"
          disabled={isFieldDisabled}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(
              labelPrefix,
              requiredBanks.map((bank, ind) => {
                if (ind === index) {
                  return {
                    ...bank,
                    bankAccountNumber: value,
                    pennydropCheck: false,
                  };
                }
                return { ...bank, pennydropCheck: bank.pennydropCheck };
              })
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`${labelPrefix}.${index}.micrCode`}
          label="MICR Code"
          placeholder="Enter MICR Code"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
        {values.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
        !['huf'].includes(application?.applicants[0]?.investorType || '') ? (
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: 'rgba(0,0,0,0.7)',
              mt: { xs: 0, sm: 5 },
              mb: { xs: 1, sm: 0 },
            }}>
            Bank type: Current{' '}
          </Typography>
        ) : (
          <UseRadioGroup
            formLabel="Bank type*"
            name={`${labelPrefix}.${index}.bankAccountType`}
            defaultValue="current"
            items={Object.keys(
              applicantStatusMasters[values.status as string] === applicantStatusMasters.NRI
                ? NRIBankAccountTypeMaster
                : BankAccountTypeMaster
            ).map((accountType) => ({
              label: accountType,
              value:
                applicantStatusMasters[values.status as string] === applicantStatusMasters.NRI
                  ? NRIBankAccountTypeMaster[accountType]
                  : BankAccountTypeMaster[accountType],
            }))}
            disabled={isFieldDisabled}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <MFTextField
          name={`${labelPrefix}.${index}.bankName`}
          label="Bank Name"
          placeholder="Enter Bank Name"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <MFTextField
          name={`${labelPrefix}.${index}.bankBranch`}
          label="Bank Branch"
          placeholder="Enter Bank Branch"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <MFTextField
          name={`${labelPrefix}.${index}.bankAddress`}
          multiline
          rows={3}
          label="Bank Address"
          placeholder="Enter Bank Address"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <MFCheckbox
          name={`${labelPrefix}.${index}.defaultBankAccount`}
          label="Default Bank Account *"
          sx={{ letterSpacing: '1px' }}
          onChange={() =>
            setFieldValue(
              labelPrefix,
              requiredBanks.map((bank, ind) => {
                if (ind === index) {
                  return {
                    ...bank,
                    defaultBankAccount: !bank.defaultBankAccount,
                  };
                }
                return {
                  ...bank,
                  defaultBankAccount: false,
                };
              })
            )
          }
          disabled={isFieldDisabled}
        />
      </Grid>
      {IS_PENNYDROP_APPLICABLE_FOR_AMC && (
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          sx={{
            width: '100%',
            flexDirection: { xs: 'column-reverse', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}>
          <Grid
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Button
              variant="outlined"
              type="button"
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                fontSize: 14,
                px: 3,
                marginRight: 1,
                width: '230px',
                borderColor: 'primary.main',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ':hover': {
                  color: 'common.white',
                  backgroundColor: 'primary.main',
                },
              }}
              onClick={() => {
                bank.bankAccountType =
                  values.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                  !['huf'].includes(application?.applicants[0]?.investorType || '')
                    ? BankAccountTypeMaster.Current
                    : bank.bankAccountType;
                handleBankVerification(values, index, !!topUpSection);
              }}
              disabled={
                bank.pennydropCheck || isFieldDisabled || pennyDropCheck(values, topUpSection)
              }>
              {!bank.pennydropVerified || !bank.pennydropCheck ? 'Verify Bank Details' : 'Verified'}
            </Button>
            {bank.loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </Grid>
          <Grid sm={6} xs={12} sx={{ position: 'relative', bottom: 10, mb: { xs: 2, sm: 0 } }}>
            <Typography
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: { xs: 0, sm: '10px 20px' },
                borderRadius: '5px',
                alignItems: 'flex-start',
                mt: 2,
                width: '100%',
                boxSizing: 'border-box',
              }}>
              <InfoOutlinedIcon color="info" fontSize="small" />
              <Typography
                sx={{
                  color: 'rgba(41, 49, 57, 0.7)',
                  fontSize: '13px',
                  fontWeight: 500,
                  ml: 1,
                }}>
                {bank.defaultBankAccount && bank.pennydropCheck && !bank.pennydropVerified
                  ? 'Penny drop verification failed, Unable to verify your bank details. Please upload your default bank account proofs In Document Details'
                  : 'The Primary Contributor’s bank account will be verified using a penny drop validation'}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        sm={12}
        xs={12}
        sx={{ position: 'relative', bottom: { xs: 5, sm: 20 }, mb: { xs: 2, sm: 0 } }}>
        <Typography
          sx={{
            color: 'rgba(41, 49, 57, 0.7)',
            fontSize: '13px',
            fontWeight: 500,
            ml: 1,
          }}>
          {bank.pennydropCheck && !bank.defaultBankAccount && !bank.pennydropVerified
            ? 'Penny drop verification failed'
            : null}
        </Typography>
      </Grid>
    </>
  );
}

export default function BankDetails(): JSX.Element {
  const [banksData, setBanksData] = useState(initialValues);
  const [bankToDelete, setBankToDelete] = useState<{
    bank: Bank;
    index: number;
    topUpBank?: boolean;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { id = '', applicants } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [pennyDropStatusPopUp, setPennyDropStatusPopUp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [addBankAfterPennyDrop, setAddBankAfterPennyDrop] = useState(bankObject);
  const [addBankAfterPennyDropCheck, setAddBankAfterPennyDropCheck] = useState(false);

  /**
   * Closes the delete bank confirmation dialog.
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Closes the penny drop status popup dialog.
   */
  const handlePopUpClose = () => {
    setPennyDropStatusPopUp(false);
  };

  useEffect(() => {
    const { banks = [], applicationType, applicants = [] } = application || {};
    const [topUpBanks, normalBanks] = partitionArray(banks) as Bank[][];
    setBanksData({
      ...banksData,
      applicationType: applicationType,
      status: applicants.length ? applicants[0].status : '',
      banks: normalBanks.length
        ? addBankAfterPennyDropCheck && normalBanks.length < 2
          ? [
              ...normalBanks.map((bank) => ({
                ...bank,
                defaultBankAccount: !!bank.defaultBankAccount,
                pennydropCheck: !!bank.pennydropCheck,
                pennydropVerified: !!bank.pennydropVerified,
                loading: false,
                bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
              })),
              { ...addBankAfterPennyDrop },
            ]
          : normalBanks.map((bank) => ({
              ...bank,
              defaultBankAccount: !!bank.defaultBankAccount,
              pennydropCheck: !!bank.pennydropCheck,
              pennydropVerified: !!bank.pennydropVerified,
              loading: false,
              bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
            }))
        : [{ ...bankObject, [!application?.isTopup ? 'applicationId' : 'topupId']: id }],
      topupBanks:
        topUpBanks.length && handleTopUp
          ? topUpBanks.map((bank) => ({
              ...bank,
              defaultBankAccount: !!bank.defaultBankAccount,
              pennydropCheck: !!bank.pennydropCheck,
              pennydropVerified: !!bank.pennydropVerified,
              loading: false,
              bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
            }))
          : handleTopUp && AddTopUpBankAccount && application?.isTopup
          ? [{ ...bankObject, [!application?.isTopup ? 'applicationId' : 'topupId']: id }]
          : [],
    });
    setAddBankAfterPennyDrop(bankObject);
    setAddBankAfterPennyDropCheck(false);
  }, [application]);

  /**
   * Handles the submission of the bank details form.
   * @param values - The form values.
   * @throws If any of the penny drop verifications are in progress.
   * @throws If any of the banks are not verified.
   * @throws If the application is being saved for later and the form is invalid.
   * @throws If the application is being saved for later and the form is valid but the save fails.
   * @throws If the application is not being saved for later and the form is invalid.
   * @throws If the application is not being saved for later and the form is valid but the update fails.
   */
  const handleSubmit = async (values: Values) => {
    try {
      const { banks, topupBanks, saveType } = values;
      if (IS_PENNYDROP_APPLICABLE_FOR_AMC) {
        const isAllBanksPennyChecked = banks
          .concat(topupBanks)
          .every((bank) => bank.pennydropCheck);
        if (!isAllBanksPennyChecked) {
          throw 'Please make sure that all the banks are verified';
        }
      }
      const {
        banks: existingBanks = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        applicationNumber,
        topupApplicationNumber,
      } = application || {};
      const updatedBanks = banks.concat(topupBanks).map((bank, ind) => ({
        ...(existingBanks[ind] || {}),
        ...bank,
        bankAccountType:
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
          !['huf'].includes(application?.applicants[0]?.investorType || '')
            ? BankAccountTypeMaster.Current
            : bank.bankAccountType,
      }));

      const checkApplication = applicationComparison(
        {
          ...application,
          banks: application?.banks?.sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
        },
        {
          ...application,
          banks: updatedBanks
            .map((bank) => {
              // eslint-disable-next-line
              const { loading, bankAddress, ...rest } = bank;
              return { ...rest };
            })
            .sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > (RiskProfileEnabled ? 5 : 4)
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 6
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              banks: updatedBanks,
              status: statusRoleBased(role, application),
              currentStep:
                applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                  ? RiskProfileEnabled
                    ? getStep(6, isSaveLater)
                    : getStep(5, isSaveLater)
                  : getStep(7, isSaveLater),
              ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push(application?.isTopup ? 'authorised-signatory' : 'document-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
            : history.push(RiskProfileEnabled ? 'risk-profiles' : 'investment-payment-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(
            `Application ${
              application?.topupInitiated ? topupApplicationNumber : applicationNumber
            } - ` + ' Saved successfully',
            {
              variant: 'success',
              autoHideDuration: 3000,
            }
          );
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push(application?.isTopup ? 'authorised-signatory' : 'document-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
            : history.push(RiskProfileEnabled ? 'risk-profiles' : 'investment-payment-details', {
                id,
                applicant1ReferenceId,
                topUpApplication: application?.topupInitiated,
              })
          : history.push(
              saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated),
              {
                topUpApplication: application?.topupInitiated,
              }
            );
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) || false;

  /**
   * Handles the penny drop verification of a bank account.
   * @param values - The form values.
   * @param ind - The index of the bank account to be verified.
   * @param topUpBank - Whether it is a top-up bank account or not.
   * @throws If the ifsc code or account number is invalid.
   * @throws If the account number is already used.
   * @throws If the default bank account is not checked.
   * @throws If the penny drop verification fails.
   */
  const handleBankVerification = async (
    values: Values,
    ind: number,
    topUpBank: boolean
  ): Promise<void> => {
    const { banks: existingBanks = [], currentStep, applicationType } = application || {};
    const { banks, topupBanks, saveType } = values;
    const currentBanks = topUpBank ? topupBanks : banks;
    setBanksData({
      ...banksData,
      topupBanks: topupBanks.map((bank, index) =>
        topUpBank && index === ind ? { ...bank, loading: true } : bank
      ),
      banks: banks.map((bank, index) =>
        !topUpBank && index === ind ? { ...bank, loading: true } : bank
      ),
    });
    try {
      if (
        !currentBanks[ind].ifscCode ||
        !currentBanks[ind].bankAccountNumber ||
        (!currentBanks[ind].bankAccountType &&
          applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL) ||
        (!currentBanks[ind].bankAccountType &&
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
          ['huf'].includes(application?.applicants[0].investorType || ''))
      ) {
        throw `Please fill all the required(*) fields in  ${getApplicantName(ind + 1, true)} ${
          topUpBank ? 'Top Up ' : ' '
        }Bank Account Details`;
      }
      if (!ifscRegex.test(currentBanks[ind].ifscCode)) {
        throw `Invalid IFSC Code in  ${getApplicantName(ind + 1, true)} ${
          topUpBank ? 'Top Up ' : ' '
        }Bank Account Details`;
      }
      if (!accountNumberRegex.test(currentBanks[ind].bankAccountNumber)) {
        throw `Please enter valid Account Number in  ${getApplicantName(ind + 1, true)} ${
          topUpBank ? 'Top Up ' : ' '
        }Bank Account Details`;
      }
      currentBanks.map((bank, index) => {
        if (index !== ind && bank.bankAccountNumber === currentBanks[ind].bankAccountNumber) {
          throw `Account number already used`;
        }
      });
      const getDefaultBankType = values.banks.map((_bank) => _bank.defaultBankAccount);
      if (!getDefaultBankType.includes(true)) {
        throw `Please check the default ${topUpBank ? 'Top Up ' : ' '}bank account`;
      }
      const checkforDefaultBankIsPennydropChecked = currentBanks.map((bank) => {
        if (bank.defaultBankAccount && bank.pennydropCheck) {
          return true;
        }
        return false;
      });
      if (
        currentBanks.map((bank) => bank.id).filter((ele) => ele).length &&
        !currentBanks[ind].defaultBankAccount &&
        !checkforDefaultBankIsPennydropChecked.includes(true)
      ) {
        throw `Please verify default ${topUpBank ? 'Top Up ' : ' '}bank account`;
      }
      const pennyDropResponse = (await dispatch(
        pennyDropVerification({
          ...currentBanks[ind],
          bankAccountType:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
            !['huf'].includes(application?.applicants[0].investorType || '')
              ? BankAccountTypeMaster.Current
              : currentBanks[ind].bankAccountType,
        })
      )) as unknown as Bank;
      const isSaveLater = saveType !== 'save and proceed';
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks: banks
              .concat(topupBanks)
              .map((bank, index) => {
                if (ind === index) {
                  return {
                    ...bank,
                    ...pennyDropResponse,
                  };
                }
                return bank.id && bank.pennydropCheck && bank;
              })
              .filter((ele) => ele) as Bank[],
            status: statusRoleBased(role, application),
            currentStep: currentStep,
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      setBanksData({
        ...banksData,

        topupBanks: topupBanks?.map((bank, index) => {
          if (index === ind) {
            return { ...bank, ...pennyDropResponse, loading: false };
          }
          if (!bank.id) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          } else if (
            bank.id &&
            !(JSON.stringify(existingBanks[index]) === JSON.stringify(banks[index]))
          ) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          }
          return bank;
        }),

        banks: banks.map((bank, index) => {
          if (index === ind) {
            return { ...bank, ...pennyDropResponse, loading: false };
          }
          if (!bank.id) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          } else if (
            bank.id &&
            !(JSON.stringify(existingBanks[index]) === JSON.stringify(banks[index]))
          ) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          }
          return bank;
        }),
      });
      if (pennyDropResponse.pennydropVerified) {
        enqueueSnackbar('Verified Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      if (pennyDropResponse.defaultBankAccount && !pennyDropResponse.pennydropVerified) {
        setPennyDropStatusPopUp(true);
      }
    } catch (e) {
      setBanksData({
        ...banksData,
        ...(topUpBank
          ? {
              topupBanks: topupBanks.map((bank, index) => {
                if (index === ind) {
                  return { ...bank, loading: false };
                }
                if (!bank.id) {
                  setAddBankAfterPennyDropCheck(true);
                  setAddBankAfterPennyDrop(bank);
                  return bank;
                } else if (
                  bank.id &&
                  !(JSON.stringify(existingBanks[index]) === JSON.stringify(banks[index]))
                ) {
                  setAddBankAfterPennyDropCheck(true);
                  setAddBankAfterPennyDrop(bank);
                  return bank;
                }
                return bank;
              }),
            }
          : {
              banks: banks.map((bank, index) => {
                if (index === ind) {
                  return { ...bank, loading: false };
                }
                if (!bank.id) {
                  setAddBankAfterPennyDropCheck(true);
                  setAddBankAfterPennyDrop(bank);
                  return bank;
                } else if (
                  bank.id &&
                  !(JSON.stringify(existingBanks[index]) === JSON.stringify(banks[index]))
                ) {
                  setAddBankAfterPennyDropCheck(true);
                  setAddBankAfterPennyDrop(bank);
                  return bank;
                }
                return bank;
              }),
            }),
      });
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  /**
   * Checks if any of the penny drop verifications are in progress.
   * @param {Values} values - The form values.
   * @param {boolean} isTopup - Whether it is a top up bank or not.
   * @returns {boolean} True if any of the penny drop verifications are in progress, false otherwise.
   */
  const pennyDropCheck = (values: Values, isTopup: boolean): boolean => {
    return (isTopup ? values.topupBanks : values.banks)
      .map((bank) => {
        if (bank.loading) {
          return true;
        }
        return false;
      })
      .includes(true);
  };

  /**
   * Checks if any of the penny drop verifications are in progress.
   * @param {Values} values - The form values.
   * @returns {boolean} True if any of the penny drop verifications are in progress, false otherwise.
   */
  const pennyDropCheckAllBank = (values: Values): boolean => {
    const { banks, topupBanks } = values;
    return banks
      .concat(topupBanks)
      .map((bank) => {
        if (bank.loading) {
          return true;
        }
        return false;
      })
      .includes(true);
  };
  return (
    <Formik
      initialValues={banksData}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, bankDetailsSchema(applicants), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'The below mentioned bank details will be used for principal payouts, Distribution payouts, and Redemption payouts'
            }
          />
          {values.banks.map((bank, index) => (
            <React.Fragment key={index}>
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                {getApplicantName(index + 1, true)} Bank Account Details
                {index !== 0 && ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: pennyDropCheck(values, false) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                      cursor: pennyDropCheck(values, false) ? 'not-allowed' : 'pointer',
                      pointerEvents: pennyDropCheck(values, false) ? 'none' : 'all',
                    }}
                    onClick={() => setBankToDelete({ bank, index, topUpBank: false })}>
                    <Typography
                      sx={{
                        // color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                        fontSize: 13,
                        ml: 'auto',
                        letterSpacing: '-0.2px',
                        // cursor: pennyDropCheck(values) ? 'not-allowed' : 'pointer',

                        //disabled={values.banks.every((bank) => bank.loading)}
                      }}>
                      Remove Bank{' '}
                    </Typography>
                    <IconButton sx={{ p: '2px' }}>
                      <DeleteOutlineIcon
                        color={pennyDropCheck(values, false) ? 'disabled' : 'error'}
                        fontSize="small"
                      />
                      {/* <CardMedia
                        component="img"
                        src="/images/delete-red.svg"
                        alt="Delete Icon"
                        sx={{
                          width: 'unset',
                          color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                        }}
                      /> */}
                    </IconButton>
                  </Box>
                )}
              </SubHeading>
              <BankAccountDetails
                index={index}
                isFieldDisabled={isFieldDisabled || application?.topupInitiated || false}
                application={application}
                values={values}
                setFieldValue={setFieldValue}
                bank={bank}
                handleBankVerification={handleBankVerification}
                pennyDropCheck={pennyDropCheck}
              />
            </React.Fragment>
          ))}
          {values.banks.length < 2 &&
            ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 2,
                  }}
                  onClick={() =>
                    setFieldValue('banks', [
                      ...values.banks,
                      { ...bankObject, [!application?.isTopup ? 'applicationId' : 'topupId']: id },
                    ])
                  }
                  disabled={pennyDropCheck(values, false) || application?.topupInitiated || false}>
                  Add Another Bank
                </Button>
              </Grid>
            )}
          {handleTopUp && application?.isTopup && (
            <>
              <SubHeading2
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                Top Up Bank Account Details
              </SubHeading2>
              {values.topupBanks.map((bank, index) => (
                <React.Fragment key={index}>
                  <SubHeading
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    {getApplicantName(index + 1, true)} Top Up Bank Account Details
                    {
                      // index !== 0 &&
                      ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: pennyDropCheck(values, true) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                            cursor: pennyDropCheck(values, true) ? 'not-allowed' : 'pointer',
                            pointerEvents: pennyDropCheck(values, true) ? 'none' : 'all',
                          }}
                          onClick={() => setBankToDelete({ bank, index, topUpBank: true })}>
                          <Typography
                            sx={{
                              fontSize: 13,
                              ml: 'auto',
                              letterSpacing: '-0.2px',
                            }}>
                            Remove Bank{' '}
                          </Typography>
                          <IconButton sx={{ p: '2px' }}>
                            <DeleteOutlineIcon
                              color={pennyDropCheck(values, true) ? 'disabled' : 'error'}
                              fontSize="small"
                            />
                          </IconButton>
                        </Box>
                      )
                    }
                  </SubHeading>

                  <BankAccountDetails
                    index={index}
                    isFieldDisabled={isFieldDisabled}
                    application={application}
                    values={values}
                    setFieldValue={setFieldValue}
                    bank={bank}
                    handleBankVerification={handleBankVerification}
                    pennyDropCheck={pennyDropCheck}
                    topUpSection={true}
                  />
                </React.Fragment>
              ))}
              {values.topupBanks.length < (AllowMultipleBank ? 2 : 1) &&
                ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                        fontSize: 14,
                        mt: 2,
                      }}
                      onClick={() =>
                        setFieldValue('topupBanks', [
                          ...values.topupBanks,
                          {
                            ...bankObject,
                            [!application?.isTopup ? 'applicationId' : 'topupId']: id,
                            isTopup: true,
                          },
                        ])
                      }
                      disabled={pennyDropCheck(values, true) || false}>
                      {values?.topupBanks?.length === 0
                        ? `Add Top Up Bank`
                        : `Add Another Top Up Bank`}
                    </Button>
                  </Grid>
                )}
            </>
          )}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => setFieldValue('saveType', 'save for later')}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
            disabled={pennyDropCheckAllBank(values)}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete bank details ?'}
            open={bankToDelete !== null}
            setOpen={() => setBankToDelete(null)}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedBanks = values[!bankToDelete?.topUpBank ? 'banks' : 'topupBanks'].map(
                  (bank, i) => {
                    // eslint-disable-next-line
                    const { loading, ...rest } = bank;
                    if (i === bankToDelete?.index) {
                      return {
                        ...rest,
                        isActive: false,
                        defaultBankAccount: bank.defaultBankAccount
                          ? false
                          : bank.defaultBankAccount,
                      };
                    }
                    return { ...rest, defaultBankAccount: true };
                  }
                );
                setBankToDelete(null);
                if (id && bankToDelete?.bank.id) {
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        banks: remainedBanks.concat(
                          values[bankToDelete?.topUpBank ? 'banks' : 'topupBanks']
                        ),
                        status: statusRoleBased(role, application),
                      },
                      applicationId: id,
                    })
                  );
                  return;
                }
                setFieldValue(
                  !bankToDelete?.topUpBank ? 'banks' : 'topupBanks',
                  remainedBanks.filter((bank) => bank.isActive)
                );
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setBankToDelete(null)}
          />
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                All the Bank Details Should be Verified
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  '.MuiButton-root': {
                    minWidth: '100px',
                  },
                }}>
                <Button variant="contained" onClick={handleClose} sx={{ color: 'common.white' }}>
                  Ok
                </Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            onClose={handlePopUpClose}
            open={pennyDropStatusPopUp}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                Unable to verify your bank details. Please upload your default bank account proofs
                In Document Details
              </Typography>
            </Box>
            <Box
              sx={{
                gap: 1.5,
                '.MuiButton-root': {
                  minWidth: '100px',
                },
                textAlign: 'center',
              }}>
              <Button variant="contained" onClick={handlePopUpClose} sx={{ color: 'common.white' }}>
                Ok
              </Button>
            </Box>
          </Dialog>
        </Grid>
      )}
    </Formik>
  );
}

import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import {
  Formik,
  setNestedObjectValues,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { Applicant, Bank } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  genderMasters,
  isNaturalParent,
  relatedRelations,
  salutationsMasters,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
} from '../../utils/constant';
import {
  getApplicantName,
  getApplicantType,
  getFirstHolderName,
  minDateForContributor,
  saveForLater,
  getRelation,
  applyRoleBasedStatus,
  applicationComparison,
  removeSingleQuote,
  checkNriPIO,
  getCountryBasedOnStatus,
  isOnboardingTypeMinor,
  preventSpecialCharacters,
  futureAge,
  isMinor,
  isFatherMother,
  statusRoleBased,
  getStep,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { FetchData, updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog, MobileNumberField } from '../commonComponents';
import { contributorDetailsSchema } from '../../utils/schema';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Relations } from '../../utils/constant';
import { mdmsCountriesList } from '../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { LoadingButton } from '@mui/lab';
import { MinorTextDatePicker } from '../../lib/formik/DatePickerWithTextFeild';
import { individualPanRegex, nonDigitRegex } from '../../utils/regex';
import { showError } from '../../redux-store/actions/auth';
import { useMdmsCountryData } from '../../utils/useDataMdms';
import FieldValidationNote from './FieldValidationNote';

const contributorObject: Partial<Applicant> = {
  name: '',
  namePrefix: '',
  motherName: '',
  fatherOrSpouseName: '',
  dateOfBirth: minDateForContributor().toString(),
  status: '',
  nationality: 'INDIAN',
  panNo: '',
  relationShipWithFirstApplicant: '',
  kraVerified: '',
  amlCheck: false,
  mobile: '',
  gender: '',
  minorPan: '',
  minorDOB: null,
  minorName: '',
  minorFatherName: '',
  minorGender: '',
  minorMobile: '',
  minorCountryCode: '+91',
  minorCountryNameAndCode: 'India: +91',
  minorEmail: '',
  minorNamePrefix: 'Mr.',
  naturalParent: '',
  guardianRelationship: '',
};
type updateApplicant = Applicant & {
  jointApplicantRelation: string | null;
};
export type Values = {
  modeOfHolding: string;
  applicants: Partial<updateApplicant>[];
  banks: Bank[];
  // poaHolderName: string;
  saveType: string;
  nationalityDropdown: string[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  // poaHolderName: '',
  saveType: 'save and proceed',
  banks: [],
  nationalityDropdown: [],
};

const MinorContributorDetails = ({
  index,
  values,
  fetchMinorData,
  minorDataFetched,
  setMinorDataFetched,
  setFieldValue,
  previousPan,
  previousDob,
  guardianPan,
  ifTopUpApplication,
}: {
  index: number;
  values: Values;
  // eslint-disable-next-line
  fetchMinorData: any;
  minorDataFetched: string;
  // eslint-disable-next-line
  setMinorDataFetched: any;
  // eslint-disable-next-line
  setFieldValue: any;
  previousPan: string;
  // eslint-disable-next-line
  previousDob: any;
  guardianPan: string | undefined;
  ifTopUpApplication: boolean;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          label="PAN"
          name={`applicants.${index}.minorPan`}
          placeholder="Enter PAN"
          required
          autoComplete="off"
          inputProps={{ style: { textTransform: 'uppercase' } }}
          onChange={(e) => {
            setFieldValue(
              `applicants.${index}.minorPan`,
              removeSingleQuote(e.target.value.toUpperCase())
            );
            if (e.target.value.toUpperCase() !== previousPan?.toUpperCase()) {
              setMinorDataFetched('changed');
            } else {
              setMinorDataFetched('fetched');
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MinorTextDatePicker
          label="Date of Birth *"
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'DD/MM/YYYY'}
          name={`applicants.${index}.minorDOB`}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
          previousDob={previousDob}
          setMinorDataFetched={setMinorDataFetched}
        />
      </Grid>
      {values.applicants.map((applicant, idx: number) => {
        if (applicant?.minorPan?.length === 10 && idx === index && applicant?.minorDOB) {
          return (
            <Grid item xs={12}>
              <Box sx={{ maxWidth: 250, mt: -1 }}>
                <LoadingButton
                  loadingPosition="end"
                  loading={minorDataFetched === 'fetching' ? true : false}
                  disabled={
                    applicant?.minorPan?.length === 10 &&
                    applicant?.minorDOB &&
                    minorDataFetched === 'changed'
                      ? false
                      : true
                  }
                  variant="contained"
                  sx={{ fontWeight: 500, fontSize: 14, width: '100%' }}
                  onClick={() =>
                    fetchMinorData(applicant.minorPan, applicant.minorDOB, values, guardianPan)
                  }>
                  Fetch Data
                </LoadingButton>
              </Box>
            </Grid>
          );
        }
      })}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorName`}
          label="Full Name *"
          placeholder="Enter Full Name"
          disabled={ifTopUpApplication}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.minorNamePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={ifTopUpApplication}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorFatherName`}
          label="Father Name *"
          placeholder="Enter Father Name"
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.minorGender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorEmail`}
          label="Email ID"
          placeholder="Enter Email ID"
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MobileNumberField
          label={'Mobile Number'}
          name={`applicants.${index}.minorMobile`}
          placeholder="Enter Mobile number"
          onKeyDown={(e) => {
            preventSpecialCharacters(e);
          }}
          countryCodeFieldName={`applicants.${index}.minorCountryNameAndCode`}
          countryCodeValue={values?.applicants[index]?.minorCountryNameAndCode || 'India: +91'}
          regexForFilterValue={nonDigitRegex}
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.guardianRelationship`}
          label="Relationship with Guardian *"
          items={relatedRelations.map((relation) => ({
            key: relation,
            value: relation,
          }))}
          disabled={ifTopUpApplication}
        />
      </Grid>
      {isFatherMother(values.applicants[index].guardianRelationship || '') && (
        <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
          <UseRadioGroup
            formLabel="Natural Parents *"
            name={`applicants.${index}.naturalParent`}
            items={Object.keys(isNaturalParent).map((item) => ({
              label: isNaturalParent[item],
              value: item,
            }))}
            value={values.applicants[index].naturalParent?.toString()}
            disabled={ifTopUpApplication}
          />
        </Grid>
      )}
      {values?.applicants[index]?.guardianRelationship === 'OTHERS' && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.guardianRelationshipTypeOther`}
            label={`Please Specify Relationship *`}
            placeholder={`Enter Please Specify Relationship`}
            disabled={ifTopUpApplication}
            // inputProps={{ style: { textTransform: 'uppercase' } }}
          />
        </Grid>
      )}
    </>
  );
};

const HolderDetails = ({
  index,
  values,
  nationalitiesMdmsResponse,
  applicationStatus,
  dataFetchedFromKRA,
  ifTopUpApplication,
}: {
  index: number;
  values: Values;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  applicationStatus?: string;
  dataFetchedFromKRA: boolean | null;
  ifTopUpApplication: boolean;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { application } = useSelector((store: RootStateType) => store.application);
  const isFieldDisabled =
    [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) || ifTopUpApplication;
  const [open, setOpen] = useState<{
    index: number;
    status: string;
  } | null>(null);

  const handleOpen = (index: number, status: string) => {
    setOpen({ index, status });
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.name`}
          label="Name *"
          placeholder="Enter Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.namePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={isFieldDisabled}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
          // onChange={({ target: { value } }) => {
          //   setFieldValue(`applicants.${index}.name`, value);
          //   index === 0 &&
          //     setFieldValue(
          //       'banks',
          //       values.banks.length
          //         ? values.banks.map((bank) => ({
          //             ...bank,
          //             pennydropCheck: false,
          //             pennydropVerified: false,
          //           }))
          //         : []
          //     );
          // }}
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.motherName`}
          label="Mother Name"
          placeholder="Enter Mother Name"
          disabled={ifTopUpApplication}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.fatherOrSpouseName`}
          label="Father/Spouse Name *"
          placeholder="Enter Father/Spouse Name"
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Date of birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of birth'}
          maxDate={minDateForContributor()}
          name={`applicants.${index}.dateOfBirth`}
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.status`}
          label="Status *"
          items={Object.keys(applicantStatusMasters).map((status) => ({
            key: applicantStatusMasters[status],
            value: status,
          }))}
          disabled={
            isFieldDisabled ||
            APPLICATION_LISTING_STATUS[applicationStatus || ''] !==
              APPLICATION_LISTING_STATUS.draft ||
            isOnboardingTypeMinor(application?.onboardingType || '')
          }
          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
            if (
              [applicantStatusMasters.NRI_PIO, applicantStatusMasters.NRI].includes(
                applicantStatusMasters[value as string]
              )
            ) {
              if (
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                APPLICATION_LISTING_STATUS.sent_to_applicant1
              ) {
                handleOpen(index, value as string);
              } else {
                setFieldValue(`applicants.${index}.status`, value);
                setFieldValue(`applicants.${index}.nationality`, 'INDIAN');
              }
            } else {
              if (
                !values.applicants
                  .map((applicant, ind) => {
                    if (ind === index) {
                      return value;
                    }
                    return applicant.status;
                  })
                  .includes('NRI') &&
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                  APPLICATION_LISTING_STATUS.sent_to_amc_approver
              ) {
                return handleOpen(index, value as string);
              }

              setFieldValue(`applicants.${index}.status`, value);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`applicants.${index}.nationality`}
          label="Nationality of Applicant *"
          items={getCountryBasedOnStatus(
            nationalitiesMdmsResponse,
            values.applicants[index].status as string
          ).map((nationality) => ({
            key: nationality.nationality,
            value: nationality.nationality,
          }))}
          disabled={
            isFieldDisabled
            // ||
            // applicantStatusMasters[values?.applicants[index]?.status as string] ===
            //   applicantStatusMasters.NRI
          }
          searchFieldPlaceholder={'Search Nationality'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.kraVerified`}
          label="CVL KRA Verified"
          placeholder="Enter CVL KRA Verified"
          sx={{
            '& .MuiInputBase-input': {
              textTransform: 'capitalize',
            },
          }}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.amlCheck`}
          label="AML Verified"
          placeholder="AML Verified"
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.panNo`}
          label="PAN"
          placeholder="Enter PAN"
          disabled={true}
        />
      </Grid>
      {index !== 0 && (
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.jointApplicantRelation`}
            label="Relationship With First Applicant *"
            items={Relations.filter((relation) => {
              const { applicants } = values;
              const selectedRelations = applicants
                .filter(
                  (_applicant, ind) =>
                    ind !== index &&
                    _applicant.jointApplicantRelation &&
                    ['Mother', 'Father', 'Spouse'].includes(_applicant.jointApplicantRelation)
                )
                .map((applicant) => applicant.jointApplicantRelation);
              return !selectedRelations.includes(relation);
            }).map((relation) => ({
              key: relation,
              value: relation,
            }))}
            disabled={ifTopUpApplication}
          />
          {values.applicants.map((applicant, idx: number) => {
            if (applicant.jointApplicantRelation === 'Others' && idx === index) {
              return (
                <MFTextField
                  name={`applicants.${index}.relationShipWithFirstApplicant`}
                  label="Please Specify Relationship *"
                  placeholder={`Enter Relationship`}
                  disabled={ifTopUpApplication}
                />
              );
            }
          })}
        </Grid>
      )}
      <ConfirmationDialog
        message={`Any change in the investor status will affect the application's status and workflow. Are you sure you want to proceed?`}
        open={open !== null}
        setOpen={handleClose}
        onSave={() => {
          setFieldValue(`applicants.${open?.index}.status`, open?.status);
          setFieldValue(`applicants.${open?.index}.nationality`, 'INDIAN');
          handleClose();
        }}
        onCancel={handleClose}
      />
      {/* <InfoDialog onClose={handleClose} open={open} content={'status changed to draft.'} /> */}
    </>
  );
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const [minorDataFetched, setMinorDataFetched] = useState('');
  const [previousPan, setPreviousPan] = useState('');
  // eslint-disable-next-line
  const [previousDob, setPreviousDob] = useState<any>(null);
  const [applicantToDelete, setApplicantToDelete] = useState<{
    index: number;
  } | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { countries } = useMdmsCountryData();

  useEffect(() => {
    (async function () {
      try {
        setMdmsCountriesList(countries.countries);
        const { applicants = [], modeOfHolding = '', banks = [] } = application || {};
        const sortedApplicants = applicants
          .map((applicant) => ({
            ...applicant,
            applicant_type: getApplicantType(applicant.applicant_type),
          }))
          .sort((applicant1, applicant2) => {
            return Number(applicant1.applicant_type) - Number(applicant2.applicant_type);
          });
        setContributorValues({
          ...contributorValues,
          banks,
          modeOfHolding:
            modeOfHolding === 'joint'
              ? 'joint'
              : sortedApplicants.length > 1
              ? 'either or survivor'
              : modeOfHolding,
          applicants: applicants.length
            ? sortedApplicants.map((applicant) => ({
                ...applicant,
                nationality: applicant.nationality
                  ? applicant.nationality.toUpperCase()
                  : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
                  ? ''
                  : 'INDIAN',
                status: checkNriPIO(applicant.status as string, applicant.statusSubType as string)
                  ? 'NRI_PIO'
                  : applicant.status,
                // eslint-disable-next-line
                amlCheck: (applicant.amlCheck ? 'Yes' : 'No') as any,
                namePrefix:
                  applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
                    ? applicant.namePrefix
                    : 'Mr.',
                jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
                  ? applicant.relationShipWithFirstApplicant
                  : 'Others',
                relationShipWithFirstApplicant: getRelation(
                  applicant.relationShipWithFirstApplicant
                )
                  ? ''
                  : applicant.relationShipWithFirstApplicant,
                minorPan: applicant.minorPan || '',
                minorDOB: applicant.minorDOB || null,
                minorName: applicant.minorName || '',
                guardianRelationship:
                  (relatedRelations.includes(applicant.guardianRelationship as string)
                    ? applicant.guardianRelationship
                    : applicant.guardianRelationship
                    ? 'OTHERS'
                    : applicant.guardianRelationship) || '',
                guardianRelationshipTypeOther:
                  (relatedRelations.includes(applicant.guardianRelationship as string)
                    ? ''
                    : applicant.guardianRelationship) || '',
                minorFatherName: applicant.minorFatherName || '',
                naturalParent: applicant.naturalParent || '',
                minorGender: applicant.minorGender || '',
                minorMobile: applicant.minorMobile || '',
                minorCountryNameAndCode: applicant.minorCountryNameAndCode || 'India: +91',
                minorEmail: applicant.minorEmail || '',
                minorNamePrefix: applicant.minorNamePrefix || 'Mr.',
                minorCountryCode: applicant.minorCountryCode || '+91',
              }))
            : [contributorObject],
          nationalityDropdown: countries.countries.map((list) => list.nationality),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application, countries]);

  const handleApplicant = async (values: Values) => {
    const { applicants = [], modeOfHolding = '', saveType, banks = [] } = values;
    const {
      id,
      currentStep,
      applicant1ReferenceId = '',
      applicationNumber,
      topupApplicationNumber,
    } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    const updatedBanks =
      applicants[0].name?.split("'").join('').trim() ===
      application?.applicants[0].name?.split("'").join('').trim()
        ? banks
        : banks.map((bank) => ({
            ...bank,
            pennydropCheck: false,
            pennydropVerified: false,
          }));
    const updatedApplicants = values.applicants.map((applicant) => {
      const { jointApplicantRelation, status, guardianRelationshipTypeOther, ...rest } = applicant;
      return {
        ...rest,
        name: removeSingleQuote(applicant.name),
        status: [applicantStatusMasters.NRI_PIO, applicantStatusMasters.NRI].includes(
          applicantStatusMasters[status as string]
        )
          ? 'NRI'
          : status,
        statusSubType: [applicantStatusMasters.NRI_PIO].includes(
          applicantStatusMasters[status as string]
        )
          ? 'NRI_PIO'
          : null,
        // eslint-disable-next-line
        amlCheck: (applicant.amlCheck as any) === 'Yes' ? true : false,
        fatherOrSpouseName: removeSingleQuote(applicant.fatherOrSpouseName),
        motherName: removeSingleQuote(applicant.motherName),
        relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
          ? jointApplicantRelation
          : removeSingleQuote(applicant.relationShipWithFirstApplicant),
        minorPan: removeSingleQuote(applicant.minorPan),
        minorName: removeSingleQuote(applicant.minorName),
        minorFatherName: removeSingleQuote(applicant.minorFatherName),
        naturalParent: isFatherMother(applicant.guardianRelationship || '')
          ? applicant.naturalParent
          : null,
        guardianRelationship: (applicant.guardianRelationship === 'OTHERS'
          ? guardianRelationshipTypeOther
          : applicant.guardianRelationship) as string,
        minorCountryNameAndCode: applicant.minorCountryNameAndCode,
        minorCountryCode: applicant.minorCountryNameAndCode?.split(':')[1].trim(),
      };
    });
    const updatedModeOfHolding =
      applicants.filter((applicant) => applicant.isActive).length === 1 ? 'single' : modeOfHolding;
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks: updatedBanks,
        applicants: updatedApplicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
        modeOfHolding: updatedModeOfHolding,
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...application!,
            banks: updatedBanks,
            applicants: updatedApplicants,
            modeOfHolding: updatedModeOfHolding,
            ...(saveType === 'save and proceed' && {
              status: statusRoleBased(role, {
                // eslint-disable-next-line
                ...application!,
                applicants: updatedApplicants,
              }),
            }),
            currentStep: getStep(2, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(
          `Application ${
            application?.topupInitiated ? topupApplicationNumber : applicationNumber
          } - ` + ' Saved successfully',
          {
            variant: 'success',
            autoHideDuration: 3000,
          }
        );
      }
      saveType === 'save and proceed'
        ? history.push('contact-details', {
            id,
            applicant1ReferenceId,
            topUpApplication: application?.topupInitiated,
          })
        : history.push(saveForLater(role, id, applicant1ReferenceId, application?.topupInitiated), {
            topUpApplication: application?.topupInitiated,
          });
    }
  };
  // eslint-disable-next-line
  async function fetchMinorData(panNumber: string, dob: any, values: any, guardianPan: string) {
    try {
      if (panNumber && !individualPanRegex.test(panNumber)) {
        throw 'Only individual PANs are allowed';
      } else if (panNumber === guardianPan) {
        throw 'Minor PAN should not be same as Guardian PAN';
      } else if (dob && dob.toString() === 'Invalid Date') {
        throw 'Invalid Date';
      } else if (dob && futureAge(dob || '')) {
        throw 'Future date cannot be entered';
      } else if (dob && !isMinor(dob)) {
        throw 'Age should be less than 18';
      } else {
        setMinorDataFetched('fetching');
        // eslint-disable-next-line
        const response = (await dispatch(FetchData({ panNumber, dob }))) as any;
        if (response.amlCheck) {
          if (response.data && Object.keys(response.data).length > 0) {
            setMinorDataFetched('fetched');
            setPreviousPan(panNumber);
            setPreviousDob(dob);
            setContributorValues({
              ...contributorValues,
              // eslint-disable-next-line
              applicants: contributorValues.applicants.map((applicant: any, index: number) => ({
                ...applicant,
                name: values.applicants[index].name,
                namePrefix: values.applicants[index].namePrefix,
                fatherOrSpouseName: values.applicants[index].fatherOrSpouseName,
                relationShipWithFirstApplicant:
                  values.applicants[index].relationShipWithFirstApplicant,
                gender: values.applicants[index].gender,
                status: values.applicants[index].status,
                dateOfBirth: values.applicants[index].dateOfBirth,
                motherName: values.applicants[index].motherName,
                panNo: values.applicants[index].panNo,
                minorPan: panNumber || '',
                minorDOB: dob || null,
                minorName: response.data.name || '',
                guardianRelationship: values.applicants[index].guardianRelationship || '',
                guardianRelationshipTypeOther:
                  values.applicants[index].guardianRelationshipTypeOther || '',
                minorFatherName: response.data.fatherName || '',
                minorGender: response.data.gender || '',
                minorMobile: response.data.mobile || '',
                minorCountryNameAndCode: response.data.minorCountryNameAndCode || 'India: +91',
                minorCountryCode: response.data.minorCountryCode || '+91',
                minorEmail: response.data.email || '',
                minorNamePrefix: response.data.minorNamePrefix || 'Mr.',
                nationality: values.applicants[index].nationality,
              })),
            });
            enqueueSnackbar('AML Verification Successful, Data Fetched', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } else {
            setMinorDataFetched('fetched');
            setPreviousPan(panNumber);
            setPreviousDob(dob);
            setContributorValues({
              ...contributorValues,
              // eslint-disable-next-line
              applicants: contributorValues.applicants.map((applicant: any, index: number) => ({
                ...applicant,
                name: values.applicants[index].name,
                namePrefix: values.applicants[index].namePrefix,
                fatherOrSpouseName: values.applicants[index].fatherOrSpouseName,
                relationShipWithFirstApplicant:
                  values.applicants[index].relationShipWithFirstApplicant,
                gender: values.applicants[index].gender,
                status: values.applicants[index].status,
                dateOfBirth: values.applicants[index].dateOfBirth,
                motherName: values.applicants[index].motherName,
                panNo: values.applicants[index].panNo,
                minorPan: panNumber || '',
                minorDOB: dob || null,
                minorName: '',
                minorFatherName: '',
                minorGender: '',
                minorMobile: '',
                minorCountryNameAndCode: 'India: +91',
                minorCountryCode: '+91',
                minorEmail: '',
                minorNamePrefix: 'Mr.',
                guardianRelationship: values.applicants[index].guardianRelationship || '',
                guardianRelationshipTypeOther:
                  values.applicants[index].guardianRelationshipTypeOther || '',
                nationality: values.applicants[index].nationality,
              })),
            });
            enqueueSnackbar('AML Verification Successful, Data Not Fetched', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          }
        } else {
          setMinorDataFetched('failed');
          setPreviousPan(panNumber);
          setPreviousDob(dob);
          setContributorValues({
            ...contributorValues,
            // eslint-disable-next-line
            applicants: contributorValues.applicants.map((applicant: any, index: number) => ({
              ...applicant,
              name: values.applicants[index].name || '',
              namePrefix: values.applicants[index].namePrefix || '',
              fatherOrSpouseName: values.applicants[index].fatherOrSpouseName || '',
              relationShipWithFirstApplicant:
                values.applicants[index].relationShipWithFirstApplicant || '',
              gender: values.applicants[index].gender || '',
              status: values.applicants[index].status || '',
              dateOfBirth: values.applicants[index].dateOfBirth,
              motherName: values.applicants[index].motherName,
              panNo: values.applicants[index].panNo,
              minorPan: panNumber || '',
              minorDOB: dob || null,
              minorName: '',
              guardianRelationship: '',
              guardianRelationshipTypeOther: '',
              minorFatherName: '',
              minorGender: '',
              minorMobile: '',
              minorCountryNameAndCode: 'India: +91',
              minorCountryCode: '+91',
              minorEmail: '',
              minorNamePrefix: 'Mr.',
              nationality: values.applicants[index].nationality,
            })),
          });
          enqueueSnackbar('AML Verification Failed, Data Not Fetched', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (e) {
      setMinorDataFetched('failed');
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  }

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      onSubmit={handleSubmit}
      //validationSchema={contributorDetailsSchema}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, contributorDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setErrors,
        setTouched,
        setFieldValue,
      }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Contributor Details of{' '}
                  {application?.onboardingType === 'minor'
                    ? 'Guardian'
                    : `${getApplicantName(index + 1)} Applicant`}
                  {index !== 0 && !application?.isTopup && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography
                        sx={{
                          color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          // cursor: 'pointer',
                        }}>
                        Remove Joint Holder{' '}
                      </Typography>
                      <IconButton
                        disabled={application?.topupInitiated || false}
                        onClick={() => setApplicantToDelete({ index })}
                        sx={{ p: '2px' }}>
                        <CardMedia
                          component="img"
                          src="/images/delete-red.svg"
                          alt="Delete Icon"
                          sx={{ width: 'unset', cursor: 'pointer' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>
                <HolderDetails
                  index={index}
                  values={values}
                  nationalitiesMdmsResponse={mdmsCountriesList}
                  applicationStatus={application?.status}
                  dataFetchedFromKRA={applicant.dataFetchedFromKRA || null}
                  ifTopUpApplication={!!application?.topupInitiated}
                />
                {isOnboardingTypeMinor(application?.onboardingType || '') && (
                  <>
                    <SubHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      Contributor Details of Minor Applicant
                    </SubHeading>
                    <MinorContributorDetails
                      index={index}
                      values={values}
                      fetchMinorData={fetchMinorData}
                      minorDataFetched={minorDataFetched}
                      setMinorDataFetched={setMinorDataFetched}
                      setFieldValue={setFieldValue}
                      previousPan={previousPan}
                      previousDob={previousDob}
                      guardianPan={applicant.panNo}
                      ifTopUpApplication={application?.topupInitiated || false}
                    />
                  </>
                )}
              </React.Fragment>
            ))}

            {!!values.applicants.length &&
              values.applicants.length < 3 &&
              ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) &&
              !isOnboardingTypeMinor(application?.onboardingType || '') && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{
                      color: 'primary.main',
                      fontWeight: 500,
                      fontSize: 14,
                      mt: 2,
                    }}
                    onClick={async () => {
                      const formErrors = await validateForm();
                      if (!Object.keys(formErrors).length) {
                        await handleApplicant(values);
                        history.push('/onboarding/enter-pan-number', {
                          redirectTo:
                            USER_ROLES.AMCAPPROVER === role
                              ? '/investment-details/edit-application/contributor-details'
                              : '/application/contributor-details',
                          application,
                          firstHolderName: getFirstHolderName(values.applicants),
                        });
                        return;
                      }
                      setTouched(setNestedObjectValues(formErrors, true));
                      setErrors(formErrors);
                    }}
                    disabled={!!application?.topupInitiated || false}>
                    Add Joint Holder
                  </Button>
                </Grid>
              )}
            {values.applicants.length > 1 && (
              <>
                <SubHeading>Redeem </SubHeading>
                <Grid item xs={12}>
                  <MFCheckbox
                    checked={values.modeOfHolding === 'either or survivor'}
                    name="holding"
                    label="Either or Survivor"
                    onChange={({ target: { checked } }) => {
                      setFieldValue('modeOfHolding', checked ? 'either or survivor' : 'joint');
                      setOpen(checked ? false : true);
                    }}
                    disabled={
                      [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) ||
                      application?.topupInitiated ||
                      false
                    }
                  />
                </Grid>
                {/* {values.modeOfHolding !== 'joint' && (
                  <>
                    <SubHeading>POA</SubHeading>
                    <Grid item xs={12}>
                      <UseRadioGroup
                        formLabel=""
                        name="poaHolderName"
                        items={values.applicants.map((applicant, index) => ({
                          label: applicant.name || `Applicant ${index + 1}`,
                          value: applicant.name || `Applicant ${index + 1}`,
                        }))}
                      />
                    </Grid>
                  </>
                )} */}
              </>
            )}
            <FieldValidationNote />
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                p: 4,
                width: { xs: '70%', sm: '70%' },
              },
            }}>
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/warning-icon-red.svg"
                sx={{ width: 'unset', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}>
              <Typography sx={{ color: '#FD3A69', lineHeight: '35px' }}>
                Disabling “Either or Survivor” will cause issues later.
              </Typography>
              <Typography sx={{ color: 'primary.main', lineHeight: '35px' }}>
                Are you willing to disable?
              </Typography>
            </Box>
            <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'joint',
                  });
                  handleClose();
                }}
                variant="outlined"
                sx={{
                  fontSize: 14,
                  color: 'primary.main',
                  minWidth: 200,
                }}>
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'either or survivor',
                  });
                  handleClose();
                }}
                sx={{
                  fontSize: 14,
                  minWidth: 200,
                }}>
                No
              </Button>
            </Grid>
          </Dialog>
          <ConfirmationDialog
            message={`Are you sure you want to delete applicant ?`}
            open={applicantToDelete !== null}
            setOpen={() => setApplicantToDelete(null)}
            onSave={async () => {
              try {
                const remainedApplicants = values.applicants.map((applicant, i) => {
                  const noOfApplicants = values.applicants.length;
                  if (i === applicantToDelete?.index) {
                    return {
                      ...applicant,
                      isActive: false,
                    };
                  }
                  if (noOfApplicants === 3 && i === 2) {
                    return {
                      ...applicant,
                      applicant_type: '2',
                    };
                  }
                  return applicant;
                });
                const defaultPayload = {
                  applicants: remainedApplicants.map((applicant) => {
                    const { jointApplicantRelation, status, ...rest } = applicant;
                    return {
                      ...rest,
                      relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
                        ? jointApplicantRelation
                        : applicant.relationShipWithFirstApplicant,
                      status: [applicantStatusMasters.NRI_PIO, applicantStatusMasters.NRI].includes(
                        applicantStatusMasters[status as string]
                      )
                        ? 'NRI'
                        : status,
                      statusSubType: [applicantStatusMasters.NRI_PIO].includes(
                        applicantStatusMasters[status as string]
                      )
                        ? 'NRI_PIO'
                        : null,
                    };
                  }),
                  modeOfHolding:
                    remainedApplicants.filter((applicant) => applicant.isActive).length === 1
                      ? 'single'
                      : values.modeOfHolding,
                };
                if (application?.id) {
                  setApplicantToDelete(null);
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        ...defaultPayload,
                        currentStep: 1,
                        status:
                          !application?.hasPOA &&
                          AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                          application?.status !== 'draft' &&
                          applyRoleBasedStatus(role)
                            ? 'sent_to_amc_approver'
                            : application?.status,
                      },
                      applicationId: application.id,
                    })
                  );
                }
              } catch (error) {
                typeof error === 'string' && dispatch(showError(error));
                console.error((error as Error).message);
              }
            }}
            onCancel={() => setApplicantToDelete(null)}
          />
        </>
      )}
    </Formik>
  );
}

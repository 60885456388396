// eslint-disable-next-line no-use-before-define
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { RootStateType } from '../redux-store/reducers';
import { PageNotFound } from './PageNotFound';

export type AuthRoutePropsType = {
  path: string;
  isLoggedIn: boolean;
  routePath?: string;
};

function LoginRequireRoute({
  isLoggedIn,
  routePath = '',
  ...rest
}: // eslint-disable-next-line no-undef
AuthRoutePropsType): JSX.Element {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isLoggedIn ? (
          <Redirect to={{ pathname: routePath || '/login', state: { from: location } }} />
        ) : (
          // 'Page you are requesting is not found.....'
          // <Redirect to={{ pathname: '/page-not-found' }} />
          <PageNotFound />
        )
      }
    />
  );
}
export default connect(
  ({ auth }: RootStateType) => ({ isLoggedIn: !!auth.token }),
  {}
)(LoginRequireRoute);

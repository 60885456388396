/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  GetBankErrorReportResponse,
  GetDistributorCommitmentAmount,
  GetOnboardingCompletedDetailsReportResponse,
  GetOnboardingDetailsReportResponse,
  GetOnBoardingSummaryReportResponse,
  OnBoardingDetailReportType,
  ReportFilterType,
} from '../types/api-types';
import {
  ONBOARDING_SUMMARY_REPORT,
  ONBOARDING_SUMMARY_REPORT_SUCCESS,
  ONBOARDING_DETAIL_REPORT,
  ONBOARDING_DETAIL_REPORT_SUCCESS,
  DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT,
  DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_SUCCESS,
  ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS,
  ONBOARDING_SUMMARY_REPORT_DOWNLOAD,
  ONBOARDING_DETAIL_REPORT_DOWNLOAD_SUCCESS,
  ONBOARDING_DETAIL_REPORT_DOWNLOAD,
  DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS,
  DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD,
  DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_SUCCESS,
  DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT,
  DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD,
  DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD_SUCCESS,
  ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL,
  ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL_SUCCESS,
  DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT,
  DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT_SUCCESS,
  ONBOARDING_COMPLETED_REPORT_DOWNLOAD,
  ONBOARDING_COMPLETED_REPORT_DOWNLOAD_SUCCESS,
  ERROR_REPORT,
  ERROR_REPORT_SUCCESS,
} from '../types/onBoarding';

const updatedFromAndToDates = (params: {
  from: string;
  to: string;
}): { from: string; to: string } => {
  const { from, to } = params;
  const fromDate = new Date(from);
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date(to);
  toDate.setHours(23, 59, 59, 999);
  return { from: fromDate.toISOString(), to: toDate.toISOString() };
};

export const getOnBoardingSummary =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<GetOnBoardingSummaryReportResponse[]> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/onboardingSummary`,
        method: 'GET',
        types: [ONBOARDING_SUMMARY_REPORT, ONBOARDING_SUMMARY_REPORT_SUCCESS],
        params: { ...params, from, to },
      },
    });
  };

export const getOnBoardingDetails =
  (params: OnBoardingDetailReportType, isTopUp?: boolean) =>
  async (dispatch: any): Promise<GetOnboardingDetailsReportResponse> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/${isTopUp ? 'topupDetails' : 'onboardingDetails'}`,
        method: 'GET',
        types: [ONBOARDING_DETAIL_REPORT, ONBOARDING_DETAIL_REPORT_SUCCESS],
        params: {
          ...params,
          from,
          to,
        },
      },
    });
  };

export const getDistributorWiseOnBoardingSummary =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<GetOnBoardingSummaryReportResponse[]> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/distributorWiseOnboardingSummary`,
        method: 'GET',
        types: [
          DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT,
          DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_SUCCESS,
        ],
        params: {
          ...params,
          from,
          to,
        },
      },
    });
  };
export const onBoardingSummaryDownload =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<any> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/onboardingSummary`,
        method: 'GET',
        types: [ONBOARDING_SUMMARY_REPORT_DOWNLOAD, ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS],
        params: { ...params, from, to, downloadFile: params.downloadFile },
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };
export const onBoardingDetailsDownload =
  (params: ReportFilterType, isTopup?: boolean) =>
  async (dispatch: any): Promise<any> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/${isTopup ? 'topupDetails' : 'onboardingDetails'}`,
        method: 'GET',
        types: [ONBOARDING_DETAIL_REPORT_DOWNLOAD, ONBOARDING_DETAIL_REPORT_DOWNLOAD_SUCCESS],
        params: {
          ...params,
          from,
          to,
          downloadFile: params.downloadFile,
        },
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const onBoardingDetailsDownloadAllFields =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<any> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/allApplicationsData`,
        method: 'GET',
        types: [
          ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL,
          ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL_SUCCESS,
        ],
        params: {
          ...params,
          from,
          to,
          downloadFile: params.downloadFile,
        },
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

export const distributorWiseOnBoardingSummaryDownload =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<any> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/distributorWiseOnboardingSummary`,
        method: 'GET',
        types: [
          DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD,
          DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS,
        ],
        params: {
          ...params,
          from,
          to,
          downloadFile: params.downloadFile,
        },
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };
export const distributorWiseCommitmentAmountDownload =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<any> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/distributorWiseCommitmentAmount`,
        method: 'GET',
        types: [
          DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD,
          DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD_SUCCESS,
        ],
        params: {
          ...params,
          from,
          to,
          downloadFile: params.downloadFile,
        },
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };
export const getDistributorWiseCommitmentAmount =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<GetDistributorCommitmentAmount[]> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/distributorWiseCommitmentAmount`,
        method: 'GET',
        types: [DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT, DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_SUCCESS],
        params: {
          ...params,
          from,
          to,
        },
      },
    });
  };

export const getCompletedApplications =
  (params: ReportFilterType, isTopUp?: boolean) =>
  async (dispatch: any): Promise<GetOnboardingCompletedDetailsReportResponse> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/${isTopUp ? 'completedTopups' : 'completedApplications'}`,
        method: 'GET',
        types: [
          DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT,
          DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT_SUCCESS,
        ],
        params: {
          ...params,
          limit: 10,
          // sort: 'applicationNumber',
          // order: 'DESC',
          from,
          to,
        },
      },
    });
  };

export const getErrors =
  (params: ReportFilterType) =>
  async (dispatch: any): Promise<GetBankErrorReportResponse> => {
    const { from, to } = updatedFromAndToDates(params);
    const { page, errorType } = params;
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/errorLogs`,
        method: 'GET',
        types: [ERROR_REPORT, ERROR_REPORT_SUCCESS],
        params: {
          page,
          pan: '',
          search: '',
          type: errorType,
          limit: 10,
          // sort: 'createdAt',
          // order: 'DESC',
          fromDate: from,
          toDate: to,
        },
      },
    });
  };

export const distributorOnboardingCompletedDownload =
  (params: ReportFilterType, isTopup?: boolean) =>
  async (dispatch: any): Promise<any> => {
    const { from, to } = updatedFromAndToDates(params);
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/${isTopup ? 'completedTopups' : 'completedApplications'}`,
        method: 'GET',
        types: [ONBOARDING_COMPLETED_REPORT_DOWNLOAD, ONBOARDING_COMPLETED_REPORT_DOWNLOAD_SUCCESS],
        params: {
          ...params,
          from,
          to,
          downloadFile: params.downloadFile,
        },
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    });
  };

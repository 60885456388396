import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../redux-store/reducers';
import {
  featureToggles,
  getNationalityList,
  jointHolderRelationsList,
  riskProfileMasterData,
} from '../redux-store/actions';
import { mdmsProp } from '../redux-store/reducers/mdmsData';
import { RiskProfileEnabled } from './constant';
import {
  FatcaMdmsData,
  getBrokers,
  getDocuments,
  getTopUpDocuments,
  getUboTypes,
} from '../redux-store/actions/application';
import { JointholderRelationMaster } from '../redux-store/types/api-types';

export const useMdmsCountryData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (mdmsData.countries.countries.length === 0) {
          await dispatch(getNationalityList());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.countries, dispatch]);

  return mdmsData;
};

export const useMdmsRiskProfileData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (RiskProfileEnabled && mdmsData.riskProfile.riskProfile.length === 0) {
          await dispatch(riskProfileMasterData());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.riskProfile, dispatch]);

  return mdmsData;
};

export const useMdmsBrokerListData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (mdmsData.BrokerList.broker_list.length === 0) {
          await dispatch(getBrokers());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.BrokerList, dispatch]);

  return mdmsData;
};

export const useMdmsDocumentData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (Object.keys(mdmsData.Document.individual).length === 0) {
          await dispatch(getDocuments());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.Document, dispatch]);

  return mdmsData;
};
export const useMdmsTopUpDocumentData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  const { application } = useSelector((store: RootStateType) => store.application);

  useEffect(() => {
    (async function () {
      try {
        if (Object.keys(mdmsData.TopUpDocument.individual).length === 0 && application?.isTopup) {
          await dispatch(getTopUpDocuments());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.TopUpDocument, dispatch, application?.isTopup]);

  return mdmsData;
};

export const useMdmsFatcaData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (mdmsData.Fatca.fatca_questions.length === 0) {
          await dispatch(FatcaMdmsData());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.Fatca, dispatch]);

  return mdmsData;
};

export const useMdmsUBOData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (!mdmsData.Ubo.uboType.length) {
          await dispatch(getUboTypes());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.Ubo, dispatch]);

  return mdmsData;
};

export const useMdmsJointHolderRelationsData = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        if (!mdmsData.jointHolderRelations.singleJointHolder.length) {
          (await dispatch(jointHolderRelationsList())) as unknown as JointholderRelationMaster;
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.jointHolderRelations, dispatch]);

  return mdmsData;
};

export const useFeatureToggles = (): mdmsProp => {
  const dispatch = useDispatch();
  const mdmsData = useSelector((state: RootStateType) => state.mdmsDataReducer);

  useEffect(() => {
    (async function () {
      try {
        const shouldFetch = Object.values(mdmsData.toggler.dateFeatureToggles).some(
          (date) => !date
        );
        if (shouldFetch) {
          await dispatch(featureToggles());
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [mdmsData.toggler, dispatch]);

  return mdmsData;
};

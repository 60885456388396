import { CommonLayout } from '../commonComponents';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch, Router } from 'react-router';
import AdditionalKYCDetails from './additionalKYCDetails';
import BankDetails from './bankDetails';
import { InvestorDetailsLayout } from './components';
import ContactDetails from './contactDetails';
import ContributorDetails from './contributorDetails';
import DistributorDetails from './distributorDetails';
import DocumentDetails from './documentDetails';
import Fatca from './fatca';
import InvestmentPaymentDetails from './investmentPaymentDetails';
import NomineeDetails from './nomineeDetails';
import { getApplicationDetails } from '../../redux-store/actions/application';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Location } from 'history';
import { RootStateType } from '../../redux-store/reducers';
import { RiskProfileEnabled, USER_ROLES } from '../../utils/constant';
import riskProfile from './riskProfileDetails';
import { getSpecificTopups } from '../../redux-store/actions/onBoarding';
import TopUpInvestmentPaymentDetails from './topUpInvestmentPaymentDetails';

export const Routes = ({
  id,
  applicant1ReferenceId,
  topUpApplication,
}: {
  id: string;
  applicant1ReferenceId?: string;
  applicationType?: string;
  topUpApplication?: boolean | null;
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const {
    auth: { role },
    application: { application: _application },
    investor: { token = '' },
  } = useSelector((store: RootStateType) => store);

  const { applicationNumber } = _application || {};

  useEffect(() => {
    async function fetchApplication() {
      try {
        if (role === USER_ROLES.INVESTOR && !token) {
          history.push(
            `/${topUpApplication ? 'topup' : 'investment-details'}/${applicant1ReferenceId}/details`
          );
          return;
        }
        if (id) {
          topUpApplication
            ? ((await dispatch(getSpecificTopups(Number(id)))) as unknown as ApplicationProps)
            : ((await dispatch(getApplicationDetails(id))) as unknown as ApplicationProps);
        } else {
          history.push('/');
          console.error('No application id found');
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    }
    // if (application === null) {
    fetchApplication();
    // }
  }, []);

  return (
    <InvestorDetailsLayout
      applicationId={id}
      applicant1ReferenceId={applicant1ReferenceId}
      applicationNumber={applicationNumber || ''}
      isTopUp={!!topUpApplication}
      topUpApplicationNumber={_application?.topupApplicationNumber || ''}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: `${path}/distributor-details`,
                  state: { id, applicant1ReferenceId, topUpApplication },
                }}
                from={location.pathname}
              />
            )}
          />
          <Route path={`${path}/distributor-details`} component={DistributorDetails} />
          <Route path={`${path}/contributor-details`} component={ContributorDetails} />
          <Route path={`${path}/contact-details`} component={ContactDetails} />
          <Route path={`${path}/additional-KYC-details`} component={AdditionalKYCDetails} />
          <Route path={`${path}/fatca`} component={Fatca} />
          <Route path={`${path}/nominee-details`} component={NomineeDetails} />
          <Route path={`${path}/bank-details`} component={BankDetails} />
          {RiskProfileEnabled && <Route path={`${path}/risk-profiles`} component={riskProfile} />}
          <Route
            path={`${path}/investment-payment-details`}
            component={topUpApplication ? TopUpInvestmentPaymentDetails : InvestmentPaymentDetails}
          />
          <Route path={`${path}/document-details`} component={DocumentDetails} />
        </Switch>
      </Router>
    </InvestorDetailsLayout>
  );
};

const ApplicationRoutes = ({
  location,
}: {
  location: Location<{
    id: string;
    applicant1ReferenceId?: string;
    applicationType?: string;
    topUpApplication?: boolean | null;
  }>;
}): JSX.Element => {
  const {
    id = '',
    applicant1ReferenceId = '',
    applicationType = '',
    topUpApplication = false,
  } = location.state || {};
  return (
    <CommonLayout>
      <Routes
        id={id}
        applicant1ReferenceId={applicant1ReferenceId}
        applicationType={applicationType}
        topUpApplication={topUpApplication}
      />
    </CommonLayout>
  );
};

export default ApplicationRoutes;
